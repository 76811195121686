/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURLGCPExtOrInt } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import {
  type AutoCompleteSuggestionsResponse,
  type SuggestionsData,
  type SuggestionsOptions,
} from '../interface';
import { type AxiosInstance } from 'axios';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { createQuery } from '@/utils/createReactQuery';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { type GetAcesAutoSuggestUsingGetParamsModel } from '@/api/types/autocomplete-types';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
const URL = `${requestBaseURLGCPExtOrInt}/sls/pd/product-navigation-search/v1/auto-suggestions`;
type Options = {
  locale: Locale;
  preview: boolean;
  searchTerm?: string;
  year?: number;
  makeId?: number;
  modelId?: number;
  vehicleTypeId?: number;
  vehicleQuestions?: string;
  storeId?: number;
};
const getSuggestions = async (options: Options, axiosInstance?: AxiosInstance) => {
  const {
    locale,
    preview,
    searchTerm,
    year,
    makeId,
    modelId,
    vehicleTypeId,
    vehicleQuestions,
    storeId,
  } = options;
  const country = getCountryFromLocale(locale);
  const customerType = 'B2C';
  const salesChannel = 'ECOMM';
  const params: GetAcesAutoSuggestUsingGetParamsModel = {
    country,
    customerType,
    preview,
    salesChannel,
    ...(searchTerm ? { searchTerm } : { searchTerm: '' }),
    storeId,
    year,
    makeId,
    modelId,
    vehicleTypeId,
    vehicleQuestions,
  };
  const response = await getAxios(axiosInstance).get<AutoCompleteSuggestionsResponse>(URL, {
    params,
    withCredentials: false,
  });
  return newSelector(response.data);
};
const newSelector = (data: AutoCompleteSuggestionsResponse): SuggestionsData => {
  return {
    generalSuggestions: data.generalSuggestions ?? [],
    productTitleSuggestions: data.productTitleSuggestions ?? [],
  };
};
const { useData: useSearchSuggestions } = createQuery<SuggestionsData, Options>(
  'searchSuggestions',
  getSuggestions
);

export const useSuggestionsACES = ({
  enabled = true,
  ...options
}: SuggestionsOptions & { enabled?: boolean }) => {
  const locale = useLocale();
  return useSearchSuggestions({
    enabled,
    locale,
    keepPreviousData: true,
    ...options,
    preview: showXMPreviewDate(),
  });
};
