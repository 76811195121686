/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import cx from 'classnames';
import React from 'react';
import styles from './Dialog.module.scss';
import { Modal } from './Modal';
import { Paper } from '@/components/Paper';

type Props = {
  open: boolean;
  children: React.ReactNode;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  onClose?: (event: React.MouseEvent | React.KeyboardEvent, a: string) => void | (() => void);
  className: string;
  classes: any;
  PaperProps?: any;
  role?: string;
  ariaLabelledBy?: string;
  ariaModal?: boolean;
  ariaLabel?: string;
  id?: string;
  overrideZindex?: boolean;
};

export const Dialog = (props: Props) => {
  const {
    open,
    onClose,
    disableEscapeKeyDown,
    className,
    classes,
    children,
    role,
    ariaModal,
    ariaLabel,
    ariaLabelledBy,
    PaperProps,
    id,
    overrideZindex,
  } = props;
  const mouseDownTarget = React.useRef<React.MouseEvent['target']>();

  const handleMouseDown = (event: React.MouseEvent) => {
    mouseDownTarget.current = event.target;
  };

  const handleBackdropClick = (event: React.MouseEvent<HTMLElement>) => {
    // Ignore the events not coming from the "backdrop".
    if (event.target !== event.currentTarget) {
      return;
    }

    if (event.target !== mouseDownTarget.current) {
      return;
    }

    if (onClose) {
      onClose(event, 'backdropClick');
    }
  };

  return (
    <Modal
      className={cx(styles.dialogRoot, className)}
      disableEscapeKeyDown={!!disableEscapeKeyDown}
      onClose={onClose}
      open={open}
      onClick={handleBackdropClick}
      overrideZindex={overrideZindex}
    >
      <div
        role={open ? 'presentation' : 'button'}
        tabIndex={open ? -1 : 0}
        className={cx(styles.dialogContainer, {
          [classes.dialogContainer]: classes.dialogContainer,
        })}
        onMouseUp={handleBackdropClick}
        onMouseDown={handleMouseDown}
      >
        <Paper
          className={cx(classes.paper, styles.paper)}
          id={id}
          role={role}
          aria-modal={ariaModal}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          {...PaperProps}
        >
          {children}
        </Paper>
      </div>
    </Modal>
  );
};
