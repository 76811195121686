/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { nanoid } from 'nanoid';
import { type QueryClient, useQuery } from '@tanstack/react-query';
import { type AxiosInstance } from 'axios';
import { useCallback } from 'react';
import type { MonetateData, MonetateResponse, MonetateFlags, MonetateFlagName } from '../interface';

type Options = {
  monetateId?: string | undefined;
  hostname: string;
  channel: string;
  requestId?: string;
};

const getKiboDecision = async (options: Options, axiosInstance?: AxiosInstance) => {
  const response = await getAxios(axiosInstance).post<MonetateResponse>(
    'https://engine.monetate.net/api/engine/v1/decide/autozone2',
    {
      channel: options.channel,
      ...(!!options.monetateId && { monetateId: options.monetateId }),
      events: [
        {
          eventType: 'monetate:decision:DecisionRequest',
          requestId: options.requestId ?? nanoid(),
          includeReporting: true,
        },
        {
          eventType: 'monetate:context:PageView',
          url: options.hostname,
          pageType: 'home',
        },
        {
          eventType: 'monetate:context:CustomVariables',
          customVariables: [
            {
              variable: 'experienceApi',
              value: 'raApi',
            },
            {
              variable: 'searchExperienceApi',
              value: 'searchRaApi',
            },
            {
              variable: 'sddShippingOptionExperience',
              value: 'fulfillmentOptionButtonV2',
            },
            {
              variable: 'affinityCtjExperience',
              value: 'affinityCtjExperience',
            },
            {
              variable: 'showOneSponsoredProductInShelf',
              value: 'showOneSponsoredProductInShelf',
            },
          ],
        },
      ],
    }
  );

  return selector(response.data);
};

const selector = (data: MonetateResponse): MonetateData => {
  const transformedData: MonetateData = {
    monetateId: data.meta.monetateId,
    flags: {},
    actions: {},
  };

  for (const response of data.data.responses) {
    for (const { json, ...action } of response.actions) {
      transformedData.actions[action.actionId] = action;

      for (const flag of Object.keys(json)) {
        const flagAlreadySaved = flag in transformedData.flags;

        if (!flagAlreadySaved) {
          transformedData.flags[flag] = {
            value: json[flag],
            actionId: action.actionId,
          };
        }
      }
    }
  }

  return transformedData;
};

const getKiboDecisionFromCache = (queryClient: QueryClient) => {
  return queryClient.getQueryData<MonetateData>(getKiboDecisionKey());
};

const selectValue = <F extends MonetateFlagName>(
  data: MonetateData | undefined,
  flag: F
): MonetateFlags[F] => {
  const value = data && flag in data.flags ? data.flags[flag].value : false;

  return value as MonetateFlags[F];
};

/**
 * Get the value of a Monetate flag
 *
 * Important: Always use `useMonetateDecisionFlag` inside React components.
 * This prevents the query data being garbage collected (SEB-10615),
 * and it also allows local updates to the cache to be reflected in the UI.
 */
const getKiboDecisionFlag = <F extends MonetateFlagName>(
  queryClient: QueryClient,
  flag: F
): MonetateFlags[F] => {
  const kiboDecisionData = getKiboDecisionFromCache(queryClient);

  return selectValue(kiboDecisionData, flag);
};

const initialData: MonetateData = {
  monetateId: undefined,
  flags: {},
  actions: {},
};

/**
 * Get the value of a Monetate flag
 */
export const useMonetateDecisionFlag = <F extends MonetateFlagName>(flag: F): MonetateFlags[F] => {
  const select = useCallback((data: MonetateData) => selectValue(data, flag), [flag]);
  const { data: decisionFlag } = useQuery({
    queryKey: getKiboDecisionKey(),
    select,
    initialData,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return decisionFlag;
};

const getKiboDecisionKey = () => ['kibo'];

const prefetchKiboDecision = (
  queryClient: QueryClient,
  options: Options,
  axiosInstance: AxiosInstance
) => {
  return queryClient.prefetchQuery({
    queryKey: getKiboDecisionKey(),
    queryFn: () => getKiboDecision(options, axiosInstance),
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: 1,
  });
};

export { getKiboDecisionKey, getKiboDecisionFromCache, prefetchKiboDecision, getKiboDecisionFlag };
