/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import { useMemo } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import type { FooterTopOrBottom } from '../interface';
import styles from './FooterBottom.module.scss';
import { FooterIcon } from './FooterIcon';
import { FooterLink } from './FooterLink';
import { CookieButton } from './CookieButton/CookieButton';
import azCommonStyles from '@/theme/globals.module.scss';
import { useLabel } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';

const LabelLinksComponent = ({
  labelLinks,
  copyright,
}: {
  labelLinks: FooterTopOrBottom[] | undefined;
  copyright: FooterTopOrBottom | undefined;
}) => {
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <div className={styles.middleSection}>
      <div className={styles.labelLinksContainer} data-testid={`footer_bottom_links_container`}>
        {labelLinks?.map((labelLink, index) => (
          <FooterLink
            key={index}
            footerSectionName="bottom"
            className={styles.labelLinkItem}
            {...labelLink}
          />
        ))}
        {matchesMobile && <CookieButton />}
      </div>
      <div className={styles.copyrightContainer} data-testid={`footer_bottom_copyright`}>
        {copyright?.label}
      </div>
    </div>
  );
};

const AppStoreComponent = ({
  legalCopy,
  appStores,
  digicert,
}: {
  legalCopy: string | undefined;
  appStores: FooterTopOrBottom[] | undefined;
  digicert: FooterTopOrBottom | undefined;
}) => {
  const locale = useLocale();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <div className={styles.appStoreContainer} data-testid={`footer_bottom_app_store`}>
      {locale === countryCodes.mx && (
        <div className={cx(styles.legalCopy, azCommonStyles['az-caption'])}>{legalCopy}</div>
      )}
      <div className={styles.footerIconsContainer}>
        {appStores?.map((appStore, index) => {
          const appStoresRegex = /AppStore|GoogleStore|GooglePlay/;
          const mobileStoreIcon =
            appStoresRegex.test(appStore.name) || appStoresRegex.test(appStore.altText);
          return (
            <div key={`appStore_${index}`}>
              <FooterIcon
                footerSectionName="bottom"
                className={styles.appStoreStyle}
                {...appStore}
                width={mobileStoreIcon ? 135 : 35}
                height={mobileStoreIcon ? 40 : 22}
              />
            </div>
          );
        })}
      </div>
      {digicert && isMobile ? (
        <DigiCertComponent className={styles.smallScreenDigicertContainer} digicert={digicert} />
      ) : null}
    </div>
  );
};

const SocialMediaComponent = ({
  socialIcons,
}: {
  socialIcons: FooterTopOrBottom[] | undefined;
}) => (
  <ul className={styles.mobileSocialIconsContainer} data-testid={`footer_bottom_social_icons`}>
    {socialIcons?.map((block, index) => (
      <li className={styles.mobileSocialIconList} key={`right-${index}`}>
        <FooterIcon
          footerSectionName="bottom"
          aTagStyle={styles.mobileSocialIcon}
          {...block}
          width={135}
          height={40}
        />
      </li>
    ))}
  </ul>
);

const DigiCertComponent = ({
  digicert,
  className,
}: {
  digicert: FooterTopOrBottom | undefined;
  className?: string;
}) => {
  const lblIcon = useLabel('label_Footer_Icon');

  return digicert ? (
    <FooterIcon
      {...digicert}
      footerSectionName="bottom"
      className={className}
      width={50}
      height={37}
      altText={`Digicert ${lblIcon}`}
    />
  ) : null;
};

type Props = {
  content: FooterTopOrBottom[] | undefined;
  socialIcons: FooterTopOrBottom[] | undefined;
};

export function FooterBottom({ content, socialIcons }: Props) {
  const legalCopy = useLabel('label_Footer_payment_method');
  const locale = useLocale();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { digicert, copyright, appStores, labelLinks } = useMemo<FooterBlocks>(
    () => getFooterLinks(content),
    [content]
  );

  if (!content || content.length === 0) {
    return null;
  }

  return (
    <div className={styles.footerBottomContainer} data-testid="footer_bottom_container">
      {locale !== countryCodes.ptBr && !isMobile ? (
        <div className={styles.mediumAndUpDigicertContainer} data-testid="footer_icon_digicert">
          {digicert ? <DigiCertComponent digicert={digicert} /> : null}
        </div>
      ) : null}

      <LabelLinksComponent labelLinks={labelLinks} copyright={copyright} />
      <div className={styles.content}>
        <AppStoreComponent appStores={appStores} legalCopy={legalCopy} digicert={digicert} />
        <SocialMediaComponent socialIcons={socialIcons} />
      </div>
    </div>
  );
}

type FooterBlocks = {
  labelLinks: FooterTopOrBottom[];
  appStores: FooterTopOrBottom[];
  copyright: FooterTopOrBottom | undefined;
  digicert: FooterTopOrBottom | undefined;
};

function getFooterLinks(content: FooterTopOrBottom[] | undefined): FooterBlocks {
  const blocks: FooterBlocks = {
    labelLinks: [],
    appStores: [],
    copyright: undefined,
    digicert: undefined,
  };

  if (!content) {
    return blocks;
  }

  for (const block of content) {
    if (block.name === 'Digicert') {
      blocks.digicert = block;
    } else if (block.name === 'Copyright') {
      blocks.copyright = block;
    } else if (block.Icon) {
      blocks.appStores.push(block);
    } else {
      blocks.labelLinks.push(block);
    }
  }

  return blocks;
}
