/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect, useCallback, useRef, type RefAttributes } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import Hidden from '@/components/Hidden';
import { localStorage } from '@/utils/localStorage';
import { Grid } from '@/components/Grid';
import { SmartLink as Link } from '@/utils/smartLink';
import { useRouter } from 'next/router';
import { isRouteProtected } from '@/protectedRoutes';
import AZLogo from '@/components/AZCustomComponent/AZLogo';
import azCommonStyles from '@/theme/globals.module.scss';
import { SearchBoxComponent } from '@/features/topNav/components/SearchBoxComponent';
import { TopNavStore } from '@/features/topNav/components/Store';
import { Vehicle } from '@/features/topNav/components/Vehicle';
import { Cart } from '@/features/topNav/components/Cart';
import HeaderPromo from '../HeaderPromo';
import { useAppState } from '@/stores/AppState';
import { routePaths } from '@/constants/routePaths';
import { dataTestIds } from './constants';
import { useLabel } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { FeatureFlag, useFeatureFlag } from '@/features/globalConfig';
import { Toast } from '@/components/Toast';
import { EMAIL } from '@/utils/validatorRegex';
import { MenuNav } from './MenuNav/MenuNav';
import { useScrollBelowThreshold } from '@/hooks/useScrollBelowThreshold';
import styles from './styles.module.scss';
import L0BotLinks from './L0BotLinks';
import { trackSuccessfulLogin } from '@/utils/analytics/track/trackSuccessfulLogin';
import type { HeaderPromoData, LoginMessageEventData } from '../../interface';
import { MobileHeaderSignIn } from './MobileHeaderSignIn/MobileHeaderSignIn';
import usePrevious from '@/utils/usePrevious';
import { saveUIState } from '@/stores/actions';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { useInteractionCheck } from '@/hooks/useInteractionCheck';
import { useProductList } from '@/features/shelf/context/useProductList';
import { useProductListView } from '@/features/shelf/context/useProductListView';
import { usePageType } from '@/hooks/usePageType';
import type { ReduxState, VehicleSummary } from '@/types';
import { deleteCookie, getDecodedCookies } from '@/utils/cookie';
import { type FixedTopHeaderProps } from './FixedHeader/FixedHeader';
import dynamic from 'next/dynamic';
import Button from '@/components/Button/Button';
import { useCMSHeaderContent } from '../../api/getCMSHeaderContent';
import { CMSExperienceInjector } from '../../../contentstack/components/CMSExperienceInjector/CMSExperienceInjector';
import { countryCodes } from '@/constants/locale';
import cx from 'classnames';
import { useIsMobileQRExperience } from '@/hooks/useIsMobileQRExperience';

type Props = {
  preferredVehicle: VehicleSummary | undefined;
  headerPromo: HeaderPromoData | undefined;
  rewardsBalance: number | undefined;
  showToast: boolean;
};

const FixedTopHeader = dynamic<FixedTopHeaderProps & RefAttributes<HTMLDivElement | null>>(
  () => import('./FixedHeader/FixedHeader')
);

const LazyMyAccount = dynamic(() =>
  import('@/components/MyAccount/myAccount').then((mod) => mod.MyAccount)
);

const LazySignInAlertDesktop = dynamic(() =>
  import('@/features/header/components/TopHeader/SignInAlert/SignInAlertDesktop').then(
    (mod) => mod.SignInAlertDesktop
  )
);
const LazySignInAlertMobile = dynamic(() =>
  import('@/features/header/components/TopHeader/SignInAlert/SignInAlertMobile').then(
    (mod) => mod.SignInAlertMobile
  )
);

const validEmail = (email: string) => EMAIL.test(String(email).toLowerCase()); // Part of the Sign in modal implementation (not checkout)

export const TopHeader = ({ preferredVehicle, headerPromo, showToast }: Props) => {
  const router = useRouter();
  const isPDP = router.pathname === '/products/[...slug]';
  const { deviceType } = useSelector(({ appData }: ReduxState) => appData);
  const [searchText, setSearchText] = useState((router.query.searchText as string) ?? '');
  const [suggestionText, setSuggestionText] = useState((router.query.searchText as string) ?? '');
  const { pageType } = usePageType();
  const { data: shelfData } = useProductList();
  const shelfResultHasAppItems = shelfData?.shelfResultHasAppItems;
  const makeOrModelName = shelfData?.makeorModelName;
  const { dispatch: dispatchAppState, state: appState } = useAppState();
  const { isYmmeTooltipEnabled } = appState || {};
  const [scrolled, setScrolled] = useState(false);
  const [showSignInAlert, setShowSignInAlert] = useState(true);
  const [programaticallyScrolled, setProgramaticallyScrolled] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [showMobileDrawer, setShowMobileDrawer] = useState(false);
  const [showDesktopDrawer, setShowDesktopDrawer] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isMenuFocused, setIsMenuFocused] = useState(false);
  const [showYMMETooltip, setShowYMMETooltip] = useState(false);
  const ymmeToolTipVisibilityTimeout = useRef<NodeJS.Timeout>();
  const fixedHeaderRef = useRef<HTMLDivElement>(null);
  const mobileHeaderTopNavRef = useRef<HTMLDivElement>(null);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const currentSize = isDesktop ? 'desktop' : isMobile ? 'mobile' : 'tablet';
  const { isMobileFilterOpen } = useProductListView();
  const prevPreferredVehicle = usePrevious(preferredVehicle);
  const protectedRoute = isRouteProtected(router.asPath);
  const inactivitySignOutMsg = useLabel('label_you_have_been_signed_out_inactivity');
  const { data: productDetails } = useProductDetails({ enabled: isPDP });
  const skipToMainContentLabel = useLabel('label_skip_to_main_content');
  const { catalogVehicleId } = preferredVehicle || {};
  const userInteracted = useInteractionCheck();
  const isShelfPageType =
    pageType?.toLowerCase()?.includes('shelf') &&
    !Object.values(routePaths).includes(router.asPath);
  const [isStickyHeaderMounted, setIsStickyHeaderMounted] = useState(false);
  const newSignInEnabled = useFeatureFlag('NEW_SIGN_IN_ENABLED') === 'true';
  const shouldRenderYmmeTooltip =
    useFeatureFlag('YMME_TOOLTIP_ENABLED') === 'true' &&
    userInteracted &&
    isYmmeTooltipEnabled &&
    !catalogVehicleId &&
    !makeOrModelName &&
    ((isShelfPageType && shelfResultHasAppItems) ||
      (isPDP && productDetails?.product?.recordType === 'app'));
  const updateShowToolTip = useCallback((isMenuOpen: boolean) => {
    if (!isMenuOpen) {
      //wait until drawer has fully closed to show the tooltip again
      ymmeToolTipVisibilityTimeout.current = setTimeout(() => {
        setShowDesktopDrawer(isMenuOpen);
        setIsMenuFocused(isMenuOpen);
      }, 210);
    } else {
      setShowDesktopDrawer(isMenuOpen);
      setIsMenuFocused(isMenuOpen);
    }
  }, []);
  const ymmeTooltipTimeoutLength = Number(useFeatureFlag('YMME_TOOLTIP_TIMEOUT_LENGTH_IN_SECS'));
  const headerRef = useRef<HTMLDivElement>(null);
  const myAccountContainerRef = useRef<HTMLDivElement>(null);
  const mobileMyAccountContainerRef = useRef<HTMLDivElement>(null);
  const { data: cmsHeaderData } = useCMSHeaderContent();
  const locale = useLocale();

  const isMobileQRExperience = useIsMobileQRExperience();

  useEffect(() => {
    window?.addEventListener('message', receiveMessage);
    return () => window?.removeEventListener('message', receiveMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Part of the Sign in modal implementation (not checkout)

  useEffect(() => {
    if (shouldRenderYmmeTooltip) {
      const length = isNaN(ymmeTooltipTimeoutLength) ? 15000 : ymmeTooltipTimeoutLength * 1000;
      const YMMETooltipTimeout = setTimeout(() => {
        setShowYMMETooltip(true);
      }, length);
      return () => {
        clearTimeout(YMMETooltipTimeout);
        clearTimeout(ymmeToolTipVisibilityTimeout.current);
      };
    } else {
      // disables tooltip after setting vehicle in YMMEContentBlock
      setShowYMMETooltip(false);
    }
  }, [shouldRenderYmmeTooltip, ymmeTooltipTimeoutLength, preferredVehicle]);

  useEffect(() => {
    if (!router.query.searchText) {
      setSearchText('');
      setSuggestionText('');
    } else {
      setSuggestionText(router.query.searchText as string);
    }
  }, [router.query, setSearchText, setSuggestionText]);

  useEffect(() => {
    if (isYmmeTooltipEnabled && prevPreferredVehicle?.catalogVehicleId && !catalogVehicleId) {
      dispatchAppState(saveUIState({ isYmmeTooltipEnabled: false }));
    }
  }, [
    dispatchAppState,
    catalogVehicleId,
    prevPreferredVehicle?.catalogVehicleId,
    isYmmeTooltipEnabled,
  ]);

  const receiveMessage = (e: MessageEvent<LoginMessageEventData>) => {
    if (e.origin !== window?.location.origin) {
      return;
    }

    if (e.data.type === 'loginsuccess') {
      const userValue = localStorage.getItem('username') || '';
      const isEmail = validEmail(userValue);
      const { pageName, pageURL, pageType, siteSection } = window.utag_data || {};
      const cookies = getDecodedCookies(['loginInteractionMethod']);
      const loginInteractionMethod = cookies.get('loginInteractionMethod');
      const displayCustomLinkNameVariable = ['Empty Cart', 'Cart Modal'].includes(
        loginInteractionMethod as string
      );
      const customLinkName =
        loginInteractionMethod === 'Cart Modal'
          ? `Cart Modal Successful Login`
          : `Empty Cart Successful Login`;

      // Successful Login Analytics Moved to src/features/account/hooks/useSignIn.ts
      if (!newSignInEnabled) {
        trackSuccessfulLogin({
          loginMethod: isEmail ? 'Email' : 'Username',
          pageName,
          pageType,
          pageURL,
          siteSection,
          loginInteractionMethod,
          ...(displayCustomLinkNameVariable && {
            customLinkName,
          }),
        });
      }

      deleteCookie('loginInteractionMethod');
      localStorage.setItem('loggedInFromEmptyCart', 'true');
      localStorage.setItem('shouldDisplayLoginMsgOnReload', 'true');

      if (pageType !== 'checkout') {
        window?.location.reload();
      }
    }
  };

  useScrollBelowThreshold(
    (belowThreshold) => {
      if (scrolled != belowThreshold || isMobile) {
        setScrolled(belowThreshold);
      }
    },
    [scrolled, headerHeight],
    isMobile ? headerHeight / 1.5 : headerHeight / 2,
    showMobileDrawer
  );

  useEffect(() => {
    if ((scrolled || programaticallyScrolled) && !isStickyHeaderMounted) {
      setIsStickyHeaderMounted(true);
    }
  }, [scrolled, programaticallyScrolled, isStickyHeaderMounted]);

  const headerRefCallback = (element: HTMLDivElement) => {
    if (element && headerHeight < 1) {
      setHeaderHeight(element.getBoundingClientRect().height);
    }
  };

  const vehicleText = preferredVehicle?.vehicleName || '';
  const handleMobileDrawer = (toggleState: boolean) => {
    setShowMobileDrawer(toggleState);
  };

  const headerPromoContent = headerPromo?.[0] && {
    ...headerPromo[0],
    deviceClassName: {
      desktop: headerPromo[0].desktop,
      tablet: headerPromo[0].tablet,
      mobile: headerPromo[0].mobile,
    },
  };
  const focusMainContent = () => {
    document.getElementsByTagName('main')[0]?.focus();
  };

  return (
    <div
      id="TopHeader"
      className={cx(styles.headerWrapper, {
        [styles.focusedHeader]: isMenuFocused || showMobileDrawer,
      })}
      ref={headerRef}
    >
      <Button
        variant="secondary"
        className={styles.skipToMainContentButton}
        onClick={focusMainContent}
      >
        {skipToMainContentLabel}
      </Button>

      <div id="headerNavWrapper" ref={headerRefCallback}>
        {cmsHeaderData?.experience_injector?.length ? (
          <CMSExperienceInjector content={cmsHeaderData?.experience_injector?.[0]} />
        ) : null}
        {isStickyHeaderMounted ? (
          <FixedTopHeader
            scrolled={scrolled}
            programaticallyScrolled={programaticallyScrolled}
            updateShowToolTip={updateShowToolTip}
            isSearchFocused={isSearchFocused}
            ref={fixedHeaderRef}
            headerRef={headerRef}
            vehicleComponent={
              <Vehicle
                dataTestIdDesktopVehicle={dataTestIds.deskTopVehicleMenuNavFixedTop}
                isFixedHeader
                vehicleText={vehicleText}
                currentSize={currentSize}
                showTooltip={
                  showYMMETooltip && !showDesktopDrawer && scrolled && !isMobileFilterOpen
                }
                tooltipAnchorEl={currentSize === 'mobile' ? fixedHeaderRef : undefined}
              />
            }
            searchBoxComponent={
              <SearchBoxComponent
                isFixedHeader
                scrolled={scrolled}
                programaticallyScrolled={programaticallyScrolled}
                setProgramaticallyScrolled={setProgramaticallyScrolled}
                showMobileDrawer={handleMobileDrawer}
                isFocused={isSearchFocused}
                setIsFocused={setIsSearchFocused}
                searchText={searchText}
                onSearchTextChange={setSearchText}
                suggestionText={suggestionText}
                searchInputDataTestId={dataTestIds.desktopSearchInputFixedTopHeader}
                magniSearchBtnDataTestId={dataTestIds.locationSearchBtn}
                magniSearchBtnDataTestId2={dataTestIds.locationSearchBtnIsMobileSearchDrawer}
                onSuggestionTextChange={setSuggestionText}
              />
            }
            toastComponent={
              showToast ? (
                <Toast
                  color={'warning'}
                  message={inactivitySignOutMsg}
                  fixed={true}
                  closed={protectedRoute}
                  severity={'warning'}
                />
              ) : null
            }
          />
        ) : null}
        <header id="mobile-top-header" data-testid="mobile-top-header" role="banner">
          <Grid container spacing={0} ref={headerRefCallback}>
            {headerPromoContent ? (
              <Grid
                item
                xs={12}
                className={cx(styles.headerPromoBlock, styles.separator)}
                id="header-promo"
              >
                <HeaderPromo content={headerPromoContent} />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Grid container spacing={0} alignItems="center" id="topHeaderWrapper">
                <Grid item xs={12}>
                  <div className={`${styles.topHeaderWrapper}`}>
                    <div
                      data-testid="menu-nav-mobile"
                      className={`${styles.addInfoItems} ${styles.menuContainer}`}
                    >
                      <MenuNav
                        id="menuNavBtnScroll"
                        onToggle={updateShowToolTip}
                        dataTestIdBtn={dataTestIds.menuNavButtonMobile}
                      />
                    </div>
                    <div className={styles.topNavTagline} data-testid="top-nav-tagline">
                      <Link id="azLogoLinkScroll" to={routePaths.blankURL}>
                        <AZLogo variant="Full" customClassName={styles.autoZoneLogo} />
                      </Link>
                    </div>
                    {locale !== countryCodes.ptBr ? (
                      <div className={styles.headerRightIcons}>
                        <div ref={mobileMyAccountContainerRef}>
                          <MobileHeaderSignIn />
                        </div>
                        <div className={styles.desktopWrapper}>
                          <Hidden implementation="js" smDown>
                            <div className={styles.myAccountCartSpacing}>
                              <div
                                className={`${styles.desktopAccountWrapper} ${styles.hoverInfoItems}`}
                                ref={myAccountContainerRef}
                              >
                                <LazyMyAccount setShowSignInAlert={setShowSignInAlert} />
                              </div>
                            </div>
                          </Hidden>
                          <FeatureFlag flag="SIGN_IN_ALERT_ENABLED">
                            {!scrolled &&
                              showSignInAlert &&
                              !isMobileQRExperience &&
                              (isMobile ? (
                                <LazySignInAlertMobile
                                  anchorRef={mobileMyAccountContainerRef.current}
                                  drawerOpen={showDesktopDrawer || showMobileDrawer}
                                />
                              ) : (
                                <LazySignInAlertDesktop
                                  anchorRef={myAccountContainerRef.current}
                                  drawerOpen={showDesktopDrawer}
                                />
                              ))}
                          </FeatureFlag>
                          <div className={`${styles.addInfoItems} ${styles.desktopCartWrapper}`}>
                            <Cart dataTestIdCartLink={dataTestIds.cartLinkTopHeaderWrapper} />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={styles.headerNavBar} id="non-sticky-header">
            <Grid container justify="center" alignItems="center" spacing={0}>
              <div className={`${styles.addInfo} ${styles.flexStartInfo}`} id="nav_wrapper">
                <div
                  data-testid="menu-nav-lg"
                  id="menu_nav"
                  className={`${styles.addInfoItems} ${styles.notMobile} ${
                    isSearchFocused ? styles.hidden : ''
                  }`}
                >
                  <MenuNav
                    id="hamburgerMenuBtn"
                    onToggle={updateShowToolTip}
                    dataTestIdBtn={dataTestIds.menuNavButton}
                  />
                </div>
                <div
                  data-testid="vehicle-menu-lg"
                  className={`${azCommonStyles['az-margin-left-xxs']} ${styles.addVehicleWrap} ${
                    styles.notMobile
                  } ${isSearchFocused ? styles.hidden : ''}`}
                >
                  <Vehicle
                    dataTestIdDesktopVehicle={dataTestIds.deskTopVehicleMenuLg}
                    currentSize={currentSize}
                    showTooltip={
                      showYMMETooltip &&
                      !isSearchFocused &&
                      !showDesktopDrawer &&
                      !scrolled &&
                      currentSize !== 'mobile'
                    }
                    vehicleText={vehicleText}
                  />
                </div>
                <div
                  className={`${styles.searchDesktopField} ${
                    isSearchFocused ? styles.searchDesktopFocused : ''
                  }`}
                >
                  <div className={styles.searchItem}>
                    <SearchBoxComponent
                      scrolled={scrolled}
                      programaticallyScrolled={programaticallyScrolled}
                      setProgramaticallyScrolled={setProgramaticallyScrolled}
                      isFocused={isSearchFocused}
                      setIsFocused={setIsSearchFocused}
                      showMobileDrawer={handleMobileDrawer}
                      searchText={searchText}
                      onSearchTextChange={setSearchText}
                      suggestionText={suggestionText}
                      searchInputDataTestId={dataTestIds.desktopSearchInput}
                      magniSearchBtnDataTestId={dataTestIds.locationSearchBtnScroll}
                      magniSearchBtnDataTestId2={dataTestIds.locatnSrchBtnScrollIsMobSrchDrawer}
                      onSuggestionTextChange={setSuggestionText}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.mobileHeaderWrapperTopNav} ${styles.addVehicleStoreWrap} ${
                    isSearchFocused ? styles.hidden : ''
                  }`}
                  ref={mobileHeaderTopNavRef}
                >
                  <div
                    data-testid="vehicle-menu-sm"
                    className={`${styles.addVehicleWrap} ${styles.mobileOnly}`}
                  >
                    <Vehicle
                      dataTestIdDesktopVehicle={dataTestIds.deskTopVehicleMenuSm}
                      currentSize={currentSize}
                      showTooltip={showYMMETooltip && !scrolled && currentSize === 'mobile'}
                      tooltipAnchorEl={mobileHeaderTopNavRef}
                      vehicleText={vehicleText}
                    />
                  </div>
                  <div
                    className={`${azCommonStyles['az-margin-left-s']} ${styles.storeLocatorWrap}`}
                  >
                    <div className={styles.mobileAddInfoItems}>
                      <TopNavStore dataTestIdStoreName={dataTestIds.storeNameTextTopHeader} />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </div>
        </header>
        {!scrolled && !programaticallyScrolled && showToast && (
          <Toast
            color={'warning'}
            message={inactivitySignOutMsg}
            closed={protectedRoute}
            severity={'warning'}
          />
        )}
      </div>
      {deviceType === 'bot' && <L0BotLinks />}
    </div>
  );
};
