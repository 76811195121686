/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { AxiosInstance, AxiosRequestHeaders, AxiosResponse } from 'axios';
import root from 'window-or-global';
import { API_URLS } from '../constants/apiUrls';
import { isBackend } from '../constants/isBackend';
import { azDataStore } from '../utils/azDataStore';
import { serverEnvironment } from './serverEnvironment.server';
import environmentConfig from './environment';

export const requestBaseURL: string =
  (typeof window !== 'undefined' ? window.location.origin : serverEnvironment.GATEWAY_URL) || '';

export const requestBaseURLGCP: string =
  (typeof window !== 'undefined' ? window.location.origin : serverEnvironment.GCP_GATEWAY_URL) ||
  '';

export const requestBaseURLGCPExtOrInt: string =
  (typeof window !== 'undefined'
    ? environmentConfig.EXTERNAL_GCP_GATEWAY_URL
    : serverEnvironment.INTERNAL_GCP_GATEWAY_URL) || '';

export const requestWarrantyUrl: string = requestBaseURL;

export const paypalCheckoutTermURL = `${requestBaseURL}/checkoutpaypalauth`;

export const paypalCartTermURL = `${requestBaseURL}/cartpaypalauth`;

export const customizeSKUCartURL = `${requestBaseURL}/customizeskucartpost`;

export const loggerURL = `${requestBaseURL}/api/log`;

export const giftCardLink = 'https://www.giftcardpartners.com/order-autozone-gift-cards';
const yextAccountID = 'me';
const mime = {
  applicationJson: 'application/json',
};
type DataStoreEntry = {
  url: string;
  cacheURL?: (url: string) => string;
  cacheable?: boolean;
};
/*
 * For the following paths, if you want to cache the reponse from the service,
 * all you need to do it add `cachable: true` to the object.
 * The default expiry time is set to 5 minutes, and you don't need to add anything
 * extra to use that time. If you would like to customize the expiry time, however,
 * you only need to add an options property to the object (i.e. options: { expiry: ${time} })
 */

export const relPath = {
  shipmentOptionsPost: {
    url: API_URLS.SHIPMENT_OPTIONS_POST,
  },
  addToCart: {
    url: API_URLS.ADD_TO_CART,
  },
  addServiceHistory: {
    url: API_URLS.ADD_SERVICE_HISTORY,
  },
  cart: {
    url: API_URLS.CART_DETAILS,
  },
  verifyOrderForSendToStoreList: {
    url: API_URLS.VERIFY_FOR_SEND_TO_STORE_LIST,
  },
  sendStoreList: {
    url: API_URLS.STORE_LIST,
  },
  contactUs: {
    url: API_URLS.CONTACT_US,
    cacheable: !isBackend,
  },
  currentUser: {
    url: API_URLS.CURRENT_USER,
  },
  currentSearchLinkingID: {
    url: API_URLS.CURRENT_SEARCH_REWARDS_LINKING_ID,
  },
  currentUserResetPwd: {
    url: API_URLS.RESET_PASSWORD,
  },
  customiseSku: {
    url: API_URLS.ADD_CONFIG_ITEM,
  },
  findNearByStores: {
    url: API_URLS.SISTER_STORES,
    cacheable: !isBackend,
  },
  forgotPwd: {
    url: API_URLS.FORGOT_PASSWORD,
  },
  currentUserVehicle: {
    url: API_URLS.CURRENT_VEHICLES,
    cacheable: !isBackend,
  },
  globalConfig: {
    url: API_URLS.SITE_GLOBAL_CONFIGS,
    cacheable: isBackend,
  },
  miniCart: {
    url: API_URLS.MINI_CART,
  },
  orderConfirmation: {
    url: API_URLS.ORDER_CONFIRMATION,
  },
  orderDetailsV2: {
    url: API_URLS.ORDER_DETAILS_V2,
  },
  orderDetailsV4: {
    url: API_URLS.ORDER_DETAILS_V4,
  },
  orderHistory: {
    url: API_URLS.ORDER_HISTORY,
    cacheable: true,
  },
  orderHistoryV2: {
    url: API_URLS.ORDER_HISTORY_V2,
    cacheable: true,
  },
  orderCancel: {
    url: API_URLS.CANCEL_ORDER,
  },
  orderShippingCancel: {
    url: API_URLS.CANCEL_ORDER_SHIPPING_GROUP,
  },
  paypalAuth: {
    url: API_URLS.PAYPAL_AUTHENTICATE,
  },
  paypalLookup: {
    url: API_URLS.PAYPAL_LOOKUP,
  },
  preferredStore: {
    url: API_URLS.PREFERRED_STORE,
    cacheable: !isBackend,
  },
  promoCodeApply: {
    url: API_URLS.CLAIM_COUPON,
  },
  promoCodeRemove: {
    url: API_URLS.REVOKE_COUPON,
  },
  quickRegister: {
    url: API_URLS.QUICK_REGISTER,
  },
  quickSubscribe: {
    url: API_URLS.QUICK_SUBSCRIPTION,
  },
  repairGuideChapterInfo: {
    url: API_URLS.REPAIR_GUIDES_CHAPTER_INFO,
  },
  rewards: {
    url: API_URLS.REWARDS,
  },
  rewardsEmail: {
    url: API_URLS.REWARDS_EMAIL,
  },
  rewardsIdVerify: {
    url: API_URLS.REWARDS_ID_VERIFY,
  },
  shippingAddress: {
    url: API_URLS.SHIPPING_ADDRESS,
  },
  shopByCategory: {
    url: API_URLS.SHOP_DEALS_BY_CATEGORY,
    cacheable: true,
  },
  logOut: {
    url: API_URLS.LOGOUT,
  },
  srValidation: {
    url: API_URLS.VALIDATE_SR_TOKEN,
  },
  startSession: {
    url: API_URLS.START_SESSION,
  },
  staticLabel: {
    url: API_URLS.MESSAGE_CONTENT,
    cacheable: isBackend,
  },
  storeCID: {
    url: API_URLS.STORE_CIDS,
  },
  swapPickUp: {
    url: API_URLS.SWAP_COMMERCE_ITEM,
  },
  trackOrder: {
    url: API_URLS.ORDER_LOOKUP,
  },
  updateShipmentMethodV2: {
    url: API_URLS.ORDER_SHIPMENT_V2,
  },
  updatePreferredVehicle: {
    url: API_URLS.UPDATE_PREFERRED_VEHICLE,
  },
  validateRewardsAccount: {
    url: API_URLS.VALIDATE_REWARDS_ACCOUNT,
  },
  validateQuestions: {
    url: API_URLS.VALIDATE_SECURITY_QUESTION,
  },
  vehicleDetails: {
    url: API_URLS.VEHICLES_DETAILS,
  },
  vehicleRepairGuide: {
    url: API_URLS.VEHICLE_REPAIR_GUIDE,
  },
  yextLocationSearch: {
    url: API_URLS.LOCATION_SEARCH,
  },
  yextGeoSearch: {
    url: API_URLS.GEO_SEARCH,
  },
  modifyVehicle: {
    url: API_URLS.CURRENT_VEHICLE,
  },
  verify: {
    url: API_URLS.ADDRESS_VERIFY,
  },
  address: {
    url: API_URLS.ORDER_ADDRESS,
  },
  addressV2: {
    url: API_URLS.ORDER_ADDRESS_V2,
  },
  shipOptions: {
    url: API_URLS.SHIPMENT_OPTIONS,
  },
  shipOptionsV2: {
    url: API_URLS.SHIPMENT_OPTIONS_V2,
  },
  cartItem: {
    url: API_URLS.ORDER_ITEM,
  },
  paymentOptions: {
    url: API_URLS.PAYMENT_OPTIONS,
  },
  commit: {
    url: API_URLS.ORDER_COMMIT,
  },
  commitv3: {
    url: API_URLS.ORDER_COMMIT_V3,
  },
  verifyForCheckout: {
    url: API_URLS.VERIFY_FOR_CHECKOUT,
  },
  giftCardPayment: {
    url: API_URLS.ORDER_PAYMENT,
  },
  customerLookup: {
    url: API_URLS.CUSTOMER_LOOKUP,
  },
  topShelfReviews: {
    url: API_URLS.TOP_SMM_SMMY_REVIEWS,
  },
  allBrands: {
    url: API_URLS.BRANDS,
  },
  appleAuthorizeMerchant: {
    url: API_URLS.APPLE_AUTHORIZE_MERCHANT,
  },
};

export const yextCompleteURL = (backendURL: string, key: keyof typeof relPath): string =>
  backendURL + yextAccountID + relPath[key].url;

export async function getATGData<T>(
  axios: AxiosInstance,
  url: string,
  headers?: AxiosRequestHeaders
): Promise<AxiosResponse<T>> {
  // siteLocale is used only on SSR as nodeJS has to maintain cache for MX and EN simulatainously
  const siteLocale =
    isBackend &&
    typeof axios?.defaults?.headers?.Referer === 'string' &&
    axios?.defaults?.headers?.Referer.endsWith('.mx')
      ? 'MX'
      : 'ANY';
  const cacheableEntry = getCacheableEntry(url);

  if (cacheableEntry) {
    const data = azDataStore.getItem(cacheableEntry.cacheURL?.(url) || url, siteLocale);

    if (data) {
      return data;
    }
  }

  const data = await axios.get(url, {
    headers: {
      Accept: mime.applicationJson,
      ...(headers ?? {}),
    },
  });

  if (cacheableEntry) {
    azDataStore.setItem(cacheableEntry.cacheURL?.(url) || url, data, siteLocale);
  }

  return data;
}

function getCacheableEntry(url: string): DataStoreEntry | void {
  for (const key in relPath) {
    // @ts-expect-error refine type
    if (relPath[key].cacheable && url.includes(relPath[key].url)) {
      // @ts-expect-error refine type
      return relPath[key];
    }
  }
}

export async function getATGDataWithoutCache<T>(
  axios: AxiosInstance,
  url: string
): Promise<AxiosResponse<T>> {
  if (isBackend) {
    return getATGData<T>(axios, url);
  }
  const headerContent = {
    headers: {
      Pragma: 'no-cache',
      cache: false,
      Accept: mime.applicationJson,
    },
  };
  const data = await axios.get(url, headerContent);
  return data;
}

export async function postATGData<T>(
  axios: AxiosInstance,
  url: string,
  data: any
): Promise<AxiosResponse<T>> {
  // pagetype used for checkout

  const pageType = root?.utag_data?.pageType;

  if (pageType) {
    const headerContent = {
      headers: {
        pageType,
      },
    };
    return axios.post(url, data, headerContent);
  }

  return axios.post(url, data);
}

export async function patchATGData<T>(
  axios: AxiosInstance,
  url: string,
  data: any
): Promise<AxiosResponse<T>> {
  return axios.patch(url, data);
}

export async function removeATGData<T>(
  axios: AxiosInstance,
  url: string,
  data?: any
): Promise<AxiosResponse<T>> {
  return axios.delete(url, {
    data,
  });
}

export async function putATGData<T>(
  axios: AxiosInstance,
  url: string,
  data: any
): Promise<AxiosResponse<T>> {
  return axios.put(url, data);
}

const getAZUrl = (key: DataStoreEntry | keyof typeof relPath): string =>
  `${requestBaseURL}${typeof key === 'string' ? relPath[key].url : key.url}`;

export default getAZUrl;
