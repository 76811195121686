/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import cx from 'classnames';
import styles from './signinIcon.module.scss';
import { LottieAnimation } from '@/components/LottieAnimation';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useFeatureFlag } from '@/features/globalConfig';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useSelector } from 'react-redux';
import type { ReduxState } from '@/types';

export function SignInAnimation({ isFixedHeader = false }) {
  const locale = useLocale();
  const router = useRouter();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const { userAuthenticated, shouldShowAnimation } = useSelector(
    ({ appData }: ReduxState) => appData
  );

  const isUSLocale = locale === countryCodes.enUS;
  const isAnimationsEnabled = useFeatureFlag('LOTTIE_ANIMATIONS_ENABLED') === 'true';
  const animationIcon = useMonetateDecisionFlag('signInAnimationIcon');
  const isOnCartPage = router.asPath === routePaths.cart;
  const isUserSignedInOnMobile = isMobile && userAuthenticated;

  const showAnimation = [
    isAnimationsEnabled,
    isUSLocale,
    Boolean(animationIcon),
    !isOnCartPage,
    !isUserSignedInOnMobile,
    !isFixedHeader,
    shouldShowAnimation,
  ].every(Boolean);

  const imgSize = isUSLocale ? { width: 20, height: 20 } : { width: 16.67, height: 18.33 };
  const iconUrl = animationIcon ? animationIcon.split('/').pop()?.split('.').shift() : `signin`;

  if (!showAnimation) {
    return (
      <img
        src={`/images/${iconUrl}.svg`}
        alt=""
        width={imgSize.width}
        height={imgSize.height}
        decoding="async"
        className={cx({
          [styles.signInIcon]: isUSLocale,
        })}
      />
    );
  }

  return (
    <LottieAnimation
      src={animationIcon}
      loop={1}
      renderer="svg"
      classes={{ root: styles.animationWrapper }}
    />
  );
}
