/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL, requestBaseURLGCPExtOrInt } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { type AxiosInstance } from 'axios';
import { type QueryClient } from '@tanstack/react-query';
import { createQuery } from '@/utils/createReactQuery';
import { useRouter } from 'next/router';
import { type PageTypeResponseModel } from '@/api/types/browse-search-types';
import { parseUrl } from '@/utils/urlHelpers';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { sanitizeUrlWithParamList } from '../../../utils/urlHelpers';
import { acceptableShelfQueryParams } from '../../../constants/queryParams';
import { useLocale } from '@/hooks/useLocale';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import type { Country, PageTypeData } from '@/types';
import { routePaths } from '@/constants/routePaths';
import { type Url } from '@/next-types';
import { useAcesFlags } from '@/features/ymme/utils/acesFlags';

type QueryOptions = {
  canonicalPath: string | undefined;
  preview: boolean;
  country: string;
  gcpUrlEnabled: boolean;
};
type PageTypeError = {
  response: {
    data: {
      altBlockScript: string;
      appId: string;
      blockScript: string;
      customLogo: null;
      firstPartyEnabled: boolean;
      hostUrl: string;
      isMobile: string;
      jsClientSrc: string;
      uuid: string;
      vid: string;
    };
    status: number;
    statusText: string;
  };
};

type PageTypeResponseData = PageTypeResponseModel;

const EXTERNAL_SITES = ['/expertips', '/company', '/diy', '/locations'];

const URL = `${requestBaseURL}/external/product-discovery/browse-search/v2/page-types`;
const GCP_URL = `${requestBaseURLGCPExtOrInt}/sls/b2c/product-discovery-seo-data-bs/v2/page-types`;

const getPageType = async (options: QueryOptions, axiosInstance?: AxiosInstance) => {
  const { canonicalPath, preview, country, gcpUrlEnabled } = options;

  const response = await getAxios(axiosInstance).get<PageTypeResponseData>(
    gcpUrlEnabled ? GCP_URL : URL,
    {
      params: {
        canonicalPath,
        preview,
        country,
      },
      ...(gcpUrlEnabled && { withCredentials: false }),
    }
  );
  return selector(response.data);
};

const {
  query: pageTypeQuery,
  useData: usePageTypeAPI,
  prefetch: prefetchPageTypeAPI,
} = createQuery<PageTypeData, QueryOptions>('pageType', getPageType);

const selector = ({
  redirectUrl,
  acesPageTypeResult,
  pageTypeResult,
}: PageTypeResponseData): PageTypeData =>
  ({
    redirectLocation: redirectUrl,
    ...acesPageTypeResult,
    ...pageTypeResult,
  } as PageTypeData);

export const usePageType = ({
  canonicalPath,
  enabled = true,
  keepPreviousData = false,
}:
  | {
      canonicalPath?: string;
      enabled?: boolean;
      keepPreviousData?: boolean;
    }
  | undefined = {}) => {
  const router = useRouter();
  const locale = useLocale();
  const { acesEnabled, acesNonVehicleEndpointsEnabled } = useAcesFlags();
  const country = getCountryFromLocale(locale);
  const parsedUrl = parseUrl(canonicalPath || router.asPath);
  const newCanonicalPath = parsedUrl.url;
  const isExternalSite = EXTERNAL_SITES.some((route) => newCanonicalPath.includes(route));

  return usePageTypeAPI({
    enabled:
      router.route !== '/errorPage' &&
      router.route === '/[...seoUrlPath]' &&
      !newCanonicalPath.includes('.') &&
      !isExternalSite &&
      !!enabled,
    keepPreviousData,
    canonicalPath: newCanonicalPath,
    staleTime: Infinity,
    preview: showXMPreviewDate(),
    country,
    gcpUrlEnabled: acesEnabled && acesNonVehicleEndpointsEnabled,
    onSuccess: async (data) => {
      if (!data.redirectLocation) {
        return;
      }

      const { intcmp } = router.query;
      const { url, query: queryParams } = parseUrl(data.redirectLocation);
      const sanitizedRedirectPath = sanitizeUrlWithParamList(
        {
          ...queryParams,
          intcmp,
        },
        acceptableShelfQueryParams,
        url
      );

      await router.replace(sanitizedRedirectPath);
    },
    onError: async (error) => {
      const pageTypeError = error as PageTypeError;
      if (pageTypeError?.response?.status === 403 && !!pageTypeError?.response?.data?.appId) {
        return;
      }
      try {
        // eslint-disable-next-line no-console
        console.log(`An error occurred while requesting page type: `, error);
        await router.replace(routePaths.errorPage as Url);
        return;
      } catch (err) {
        throw new Error(`There was a problem redirecting to ${routePaths.errorPage}`);
      }
    },
  });
};

export const getPageTypeFromCache = (
  queryClient: QueryClient,
  {
    canonicalPath,
    country,
    gcpUrlEnabled,
  }: {
    canonicalPath: string;
    country: Country;
    gcpUrlEnabled: boolean;
  }
) => {
  const parsedUrl = parseUrl(canonicalPath).url;
  const options: QueryOptions = {
    canonicalPath: parsedUrl,
    preview: showXMPreviewDate(),
    country,
    gcpUrlEnabled,
  };
  return queryClient.getQueryData<PageTypeData>(pageTypeQuery.getFullKey(options));
};

export const prefetchPageType = async (
  queryClient: QueryClient,
  axiosInstance: AxiosInstance,
  {
    canonicalPath,
    country,
    gcpUrlEnabled,
  }: {
    canonicalPath: string;
    country: Country;
    gcpUrlEnabled: boolean;
  }
) => {
  const parsedUrl = parseUrl(canonicalPath).url;

  const options: QueryOptions = {
    canonicalPath: parsedUrl,
    preview: showXMPreviewDate(),
    country,
    gcpUrlEnabled,
  };

  return prefetchPageTypeAPI(queryClient, options, axiosInstance);
};
