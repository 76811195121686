/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React, { useMemo } from 'react';
import { SmartLink as Link } from '@/utils/smartLink';
import { useCMSHeaderContent } from '../../api/getCMSHeaderContent';
import { usePageType } from '@/features/category/api/getPageType';

const L0BotLinks = () => {
  const { data: cmsHeaderData } = useCMSHeaderContent();
  const headerNavigation = cmsHeaderData?.headerNavigation;
  const mostPopularHeaderNavigation = useMemo(() => {
    return headerNavigation?.filter((item) =>
      /General|Most Popular|Más Populares|Artículos más buscados para|Los más populares|Mais populares/.test(
        item?.menu_section?.section_title ?? ''
      )
    );
  }, [headerNavigation]);

  const popularPartsList = mostPopularHeaderNavigation?.[0].menu_section?.menu_link;

  const topNavDealsfromHeader = useMemo(() => {
    return headerNavigation?.filter((item) =>
      /Shop Deals|Shop By|Trustworthy Advice|Ver Ofertas|Búsqueda Por|Consejos Confiables|Ofertas para você!/.test(
        item?.menu_section?.section_title ?? ''
      )
    );
  }, [headerNavigation]);
  const { data: pageTypeData } = usePageType();

  const topNavDeals = useMemo(() => {
    return topNavDealsfromHeader?.map((item) => {
      return item?.menu_section?.menu_link;
    });
  }, [topNavDealsfromHeader]);

  return (
    <nav data-testid="l0-bot-links">
      <ul>
        {popularPartsList?.map((part) => (
          <li key={part.link_name}>
            <Link
              to={
                pageTypeData?.makeModelYearPath
                  ? part?.link_cta?.trim() + pageTypeData.makeModelYearPath
                  : part?.link_cta ?? '#'
              }
            >
              {part.link_name}
            </Link>
          </li>
        ))}
        {topNavDeals?.filter(Boolean).map((deals) => {
          if (Array.isArray(deals)) {
            return deals.map((deal) => (
              <li key={deal.link_cta}>
                <Link to={deal.link_cta ?? ''}>{deal.link_name}</Link>
              </li>
            ));
          }
        })}
      </ul>
    </nav>
  );
};

export default L0BotLinks;
