/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState } from 'react';
import type { FooterTopOrBottom } from '../interface';
import { ContentBlock } from './ContentBlock';
import styles from './FooterTop.module.scss';
import dynamic from 'next/dynamic';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';

const LazySearchStoreModal = dynamic(
  () =>
    import('@/components/Store/StoreModal/SearchStoreModal').then((mod) => mod.SearchStoreModal),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

type Props = {
  content: FooterTopOrBottom[] | undefined;
  socialIcons: FooterTopOrBottom[] | undefined;
};

export function FooterTop({ content, socialIcons }: Props) {
  const [openStoreModal, setOpenStoreModal] = useState(false);

  const handleCloseModal = () => {
    setOpenStoreModal(false);
  };

  const handleOpenModal = () => {
    setOpenStoreModal(true);
  };

  return (
    <div className={styles.footerTopContainer}>
      <div className={styles.topButtons}>
        {content?.map(
          (block, index) =>
            !block.Icon && (
              <ContentBlock
                key={`left-${index}`}
                CTALink={block.CTALink}
                imageUrl={block.imageUrl}
                name={block.name}
                altText={block.altText}
                label={block.label}
                Icon={block.Icon}
                handleOpenModal={handleOpenModal}
                index={index}
              />
            )
        )}
      </div>
      <hr />
      <ul className={styles.footerTopSocialIconContainer}>
        {socialIcons?.map((block, index) => (
          <li key={index.toString()}>
            {' '}
            <ContentBlock key={`right-${index}`} {...block} />
          </li>
        ))}
      </ul>
      {openStoreModal ? <LazySearchStoreModal closeStoreModal={handleCloseModal} /> : null}
    </div>
  );
}
