/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { PureComponent } from 'react';
import root from 'window-or-global';
import { RewardsModals, RewardsModalsV2 } from '@/features/mergeRewards';
import type { Props, State } from './types';
import { getDecodedCookies, setCookie } from '@/utils/cookie';
import { analyticsCookie } from '@/constants/analytics/analyticsCookie';
import { useGetAllHeaderData } from '../api/useGetAllHeaderData';
import { trackPreferredVehicleData } from '@/features/ymme/utils/trackPreferredVehicleData';
import { isServer } from '@/utils/isServer';
import { usePreferredVehicle } from '../hooks/usePreferredVehicle';
import { useRewardsActivity } from '@/features/rewards/api/getRewardsActivity';
import { useProductListView } from '@/features/shelf/context/useProductListView';
import { TopHeader } from './TopHeader/TopHeader';
import { useTaxonomyFilteredHeaderPromo } from '../hooks/useTaxonomyFilteredHeaderPromo';
import { localStorage } from '@/utils/localStorage';
import { OptionalSuspense } from '@/features/performance/components/OptionalSuspense';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useMyProfile } from '@/features/myProfile/api/getMyProfile';
import { STAND_ALONE_USER_TYPE } from '@/features/header';

class HeaderComp extends PureComponent<Props, State> {
  eCookieId = false;

  componentDidMount() {
    const { preferredVehicle } = this.props;
    if (preferredVehicle?.catalogVehicleId) {
      trackPreferredVehicleData(preferredVehicle);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { headerData, storeData, rewardsData, rewardsBalance } = this.props;
    const userType = headerData?.myAccountMap?.userType;
    const nddMarket = headerData?.analyticsData?.nddMarket;
    const nddHub = headerData?.analyticsData?.nddHubStore;
    const nddStore = headerData?.analyticsData?.nddStore;
    /** Analytics start */

    setCookie(analyticsCookie.cookieUserType, userType);
    setCookie(analyticsCookie.cookieNddMarket, nddMarket);
    setCookie(analyticsCookie.cookieNddHubStore, nddHub);
    setCookie(analyticsCookie.cookieNddStore, nddStore);
    setCookie(analyticsCookie.cookieAvailableRewardBalance, rewardsBalance?.toString());

    if (prevProps.rewardsData !== rewardsData && rewardsBalance && rewardsBalance > 0) {
      setCookie(analyticsCookie.cookieRewardPoints, rewardsBalance.toString());
    }

    if (prevProps.storeData !== storeData && storeData && storeData.storeNumber) {
      const { storeNumber, zip } = storeData;
      setCookie(analyticsCookie.cookiePreferredStoreId, storeNumber);
      setCookie(analyticsCookie.cookiePreferredZipCode, zip);

      if (root.utag_data) {
        root.utag_data.preferredZipCode = zip;
        root.utag_data.preferredStoreId = storeNumber;
      }
    }
    /** Analytics end */

    // for loggedin user vehicleInfo=undefined for 1st time
    const vehicleMapData = headerData?.vehicleMap; // for loggedin user vehicleInfo=undefined for 1st time

    if (
      vehicleMapData &&
      vehicleMapData.vehicleId !== prevProps.headerData?.vehicleMap?.vehicleId
    ) {
      trackPreferredVehicleData(vehicleMapData);
    } // change for Headerresponse reader
  }

  render() {
    const {
      headerData,
      headerPromo,
      rewardsBalance,
      preferredVehicle,
      isMobileFilterOpen,
      isUS,
      isStandAloneUser,
    } = this.props;
    if (!isServer) {
      const cookies = getDecodedCookies(['eCookieId']);
      this.eCookieId = Boolean(cookies.get('eCookieId'));
    }
    const canShowToastNotification = localStorage.getItem('canShowToastNotification') === 'true';
    const rememberMe = headerData?.myAccountMap?.autoLogin === 'true';
    const showToast = canShowToastNotification && !rememberMe;

    if (this.eCookieId && typeof document !== 'undefined' && root.utag_data) {
      const utag = root.utag_data;
      const cookies = getDecodedCookies(['eCookieId']);
      utag.eCookieId = cookies.get('eCookieId');
    }

    if (headerData) {
      const tokenId = headerData?.apiResponseHeaders?.token_id;

      if (tokenId && typeof document !== 'undefined' && root.utag_data) {
        const utag = root.utag_data;
        const splitToken = tokenId.split('.');
        utag.jSessionId = splitToken[0] ? splitToken[0].toString() : '';
        utag.serverInfo = splitToken[1] ? splitToken[1].toString() : '';
      }

      const myAccountMap = headerData?.myAccountMap;
      const profileData = myAccountMap?.profileId || '';
      const rewardsData = myAccountMap?.rewardsId || '';

      if (myAccountMap && typeof document !== 'undefined') {
        document.cookie = `profileId =${profileData};path=/`; // TODO: refactor rewardsId cookie as it adds complexity to the process.
        // This cookie is being used in the create account flow

        document.cookie = `rewardsId =${rewardsData};path=/`;
        let rewardsStatus = false;
        rewardsStatus = rewardsData !== '' && rewardsData.length > 0;

        if (root.utag_data) {
          root.utag_data.profileId = profileData;
          root.utag_data.rewardsId = rewardsData;
          root.utag_data.rewardsCustomerStatus = rewardsStatus;
        }
      }
    }

    return (
      <>
        {!isMobileFilterOpen && (
          <OptionalSuspense>
            <TopHeader
              showToast={showToast}
              preferredVehicle={preferredVehicle}
              headerPromo={headerPromo}
              rewardsBalance={rewardsBalance}
            />
          </OptionalSuspense>
        )}
        {isUS && isStandAloneUser ? <RewardsModalsV2 /> : <RewardsModals />}
      </>
    );
  }
}

export function Header() {
  const { data: userData } = useMyProfile();
  const { isMobileFilterOpen } = useProductListView();
  const { headerData, headerPromo, storeData } = useGetAllHeaderData();
  const maybeCurrentTaxonomyFilteredPromo = useTaxonomyFilteredHeaderPromo();
  const preferredVehicle = usePreferredVehicle();
  const { data: rewardsData } = useRewardsActivity();

  const rewardsBalance = rewardsData?.loyaltyAccount?.rewardBalance ?? 0;
  const roundedRewardsBalance = Math.round(rewardsBalance * 100) / 100;
  const locale = useLocale();
  const isUS = locale === countryCodes.us;
  const userType = userData?.userType;
  const isStandAloneUser = userType === Number(STAND_ALONE_USER_TYPE);

  return (
    <HeaderComp
      isMobileFilterOpen={isMobileFilterOpen}
      rewardsData={rewardsData}
      rewardsBalance={roundedRewardsBalance}
      headerData={headerData}
      headerPromo={maybeCurrentTaxonomyFilteredPromo ?? headerPromo}
      storeData={storeData}
      preferredVehicle={preferredVehicle}
      isUS={isUS}
      isStandAloneUser={isStandAloneUser}
    />
  );
}
