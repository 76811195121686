/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { AxiosInstance } from 'axios';
import type { Dispatch, ThunkAction, GetState } from '../types';
import azURL, { relPath } from '../config/serviceAPI';
import actionTypes from '../types/action';
import { deleteCookie } from '@/utils/cookie';
import { analyticsCookie } from '@/constants/analytics/analyticsCookie';
import { deletePreferredVehicleCookies } from '@/utils/analytics/dataLayer/preferredVehicleCookies';
import { type QueryClient } from '@tanstack/react-query';
import { localStorage } from '@/utils/localStorage';
import { HEADER_PRIMARY_KEY } from '@/features/header/api/getHeader';
import { COOKIES } from '@/constants/cookies';
import { dispatchEvent } from '@/utils/eventPublisher';

export const signout =
  (queryClient: QueryClient, onCompletion?: () => void): ThunkAction =>
  async (dispatch: Dispatch, _: GetState, axios: AxiosInstance) => {
    try {
      await axios.post(azURL(relPath.logOut), {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      });

      localStorage.removeItem('rememberMeChecked');
      localStorage.removeItem('email');
      localStorage.removeItem('loggedInFrom');

      deleteCookie(COOKIES.JSESSIONID);
      deleteCookie(analyticsCookie.cookieisSignedIn);
      deleteCookie(analyticsCookie.cookieHashedEId);
      deleteCookie(COOKIES.bearer_token);
      deletePreferredVehicleCookies();
      await dispatch({ type: actionTypes.LOG_OUT });
      queryClient.removeQueries(['user']);
      queryClient.removeQueries(['myProfile']);
      queryClient.removeQueries(['currentUserData']);
      queryClient.removeQueries(['landingPage']);
      queryClient.removeQueries(['savedForLaterItems']);
      queryClient.removeQueries(['savedForLaterInfinite']);
      await queryClient.refetchQueries({
        queryKey: [HEADER_PRIMARY_KEY],
      });
    } catch (err: any) {
      dispatch({
        type: 'SIGNOUT_FAILURE',
        errorLog: err,
      });

      return Promise.reject();
    } finally {
      onCompletion?.();
      dispatchEvent('signOut');
    }
  };
