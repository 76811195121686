/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useFeatureFlag } from '@/features/globalConfig/hooks/useFeatureFlag';
import { Fragment, Suspense, type PropsWithChildren } from 'react';

export function OptionalSuspense({ children }: PropsWithChildren) {
  const enableSuspense = useFeatureFlag('PERFORMANCE_SELECTIVE_HYDRATION_ENABLED') === 'true';
  const Wrapper = enableSuspense ? Suspense : Fragment;

  return <Wrapper>{children}</Wrapper>;
}
