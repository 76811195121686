/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { isTrue } from '@/utils/isTrue';
import { type AxiosInstance, type AxiosResponse } from 'axios';
import { type QueryClient, type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { type HeaderData, type HeaderResponse } from '../interface';
import { dispatchEvent } from '@/utils/eventPublisher';
import { useAcesFlags } from '@/features/ymme/utils/acesFlags';
import { type VehicleSummary } from '@/types';

const getHeaderData = async (
  {}: QueryFunctionContext<ReturnType<typeof getHeaderDataKey>>,
  axiosInstance?: AxiosInstance,
  flags?: { acesEnabled: boolean }
) => {
  const url = flags?.acesEnabled
    ? `${requestBaseURL}/ecomm/b2c/v3/page/header`
    : `${requestBaseURL}/ecomm/b2c/v2/page/header`;

  const params = {
    hideXMContent: flags?.acesEnabled ? undefined : true,
  };
  const response = await getAxios(axiosInstance).get<HeaderResponse>(url, { params });
  dispatchEvent('getHeader', response.data);

  return selector(response.data, response.headers, flags?.acesEnabled);
};

export const selector = (
  responseResult: HeaderResponse,
  headers: AxiosResponse<HeaderResponse>['headers'],
  acesEnabled?: boolean
): HeaderData => {
  let vehicleMap: VehicleSummary | undefined;

  if (acesEnabled) {
    // Get all top level properties except acesVehicleAttributes
    const vehicleMapTopLayer = { ...responseResult.vehicleMap };
    delete vehicleMapTopLayer.acesVehicleAttributes;

    // Get all properties from acesVehicleAttributes including direct properties
    const acesVehicleProps = { ...responseResult.vehicleMap.acesVehicleAttributes };
    const { vehicleQuestionsIds, vehicleQuestionsNames, ...acesTopLevelProps } =
      acesVehicleProps as {
        vehicleQuestionsIds: Record<string, number>;
        vehicleQuestionsNames: Record<string, string>;
      };

    // Extract and rename the specific properties we want
    const {
      driveTypeName,
      engineName,
      makeName,
      modelName,
      subModelName,
      regionName,
      ...remainingQuestionNames
    } = vehicleQuestionsNames || {};

    // Create new object with renamed properties
    const renamedProperties = {
      ...remainingQuestionNames,
      driveType: driveTypeName,
      engine: engineName,
      make: makeName,
      model: modelName,
      subModel: subModelName,
      region: regionName,
    } as Record<string, string>;

    // Merge all layers into a flat structure
    vehicleMap = {
      ...vehicleMapTopLayer,
      ...acesTopLevelProps,
      ...renamedProperties,
      ...vehicleQuestionsIds,
    } as VehicleSummary;
  }

  return {
    analyticsData: responseResult.analyticsData,
    isStagingApp: isTrue(responseResult.isStagingApp),
    headerPromo: responseResult.headerPromo?.contents,
    miniCartMap: responseResult.miniCartMap,
    myAccountMap: responseResult.myAccountMap,
    storeDetails: responseResult.storeDetails || undefined,
    storeNumber: responseResult.storeDetails?.storeNumber,
    vehicleMap: vehicleMap ? vehicleMap : responseResult.vehicleMap,
    apiResponseHeaders: {
      token_id: headers.token_id as string | undefined,
    },
    segmentMessageContent: responseResult.segmentMessageContent,
    miniCartSuccessNote: false,
    segments: responseResult.cmsSegmentedContent,
    savedOrderMap: responseResult.savedOrderMap,
  };
};

export const HEADER_PRIMARY_KEY = 'header';

// TODO web5 tie header data with `vehicleId` so that it updates automatically whenever vehicle is changed.
// TODO web5 tie header data with `itemCount` from `miniCartMap` so that it updates automatically whenever item count in cart is changed.
// This should make us remove all the imperative `refetchHeaderData` calls.
// We should consider optimistic updates as well to avoid delays.
export const getHeaderDataKey = () => [HEADER_PRIMARY_KEY, {}] as const;

export function useHeaderData<SelectReturnType = HeaderData>(options?: {
  select?: (headerData: HeaderData) => SelectReturnType;
}) {
  const { acesEnabled } = useAcesFlags();

  return useQuery({
    queryKey: getHeaderDataKey(),
    queryFn: (context: QueryFunctionContext<ReturnType<typeof getHeaderDataKey>>) =>
      getHeaderData(context, undefined, { acesEnabled }),
    staleTime: Infinity,
    select: (data) => {
      return options?.select ? options.select(data) : (data as SelectReturnType);
    },
  });
}

export const prefetchHeaderData = (
  queryClient: QueryClient,
  axiosInstance: AxiosInstance,
  flags: { acesEnabled: boolean }
) => {
  return queryClient.prefetchQuery({
    queryKey: getHeaderDataKey(),
    queryFn: (context: QueryFunctionContext<ReturnType<typeof getHeaderDataKey>>) =>
      getHeaderData(context, axiosInstance, flags),
  });
};

export const getHeaderDataFromCache = (queryClient: QueryClient) => {
  return queryClient.getQueryData<HeaderData>(getHeaderDataKey());
};
