/**
 * Copyright 2019-2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

const actionTypes = {
  ADD_TO_CART_SHELF_FAILURE: 'ADD_TO_CART_SHELF_FAILURE',
  ADD_TO_CART_SHELF_REQUESTING: 'ADD_TO_CART_SHELF_REQUESTING',
  ADD_TO_CART_SUCCESS: 'ADD_TO_CART_SUCCESS',
  ADDRESS_DETAILS_INVALID: 'ADDRESS_DETAILS_INVALID',
  ADDRESS_SUCCESS: 'ADDRESS_SUCCESS',
  BILLING_ADDRESS_INDICATOR_UPDATE: 'BILLING_ADDRESS_INDICATOR_UPDATE',
  BILLING_ADDRESS_INITIALIZE: 'BILLING_ADDRESS_INITIALIZE',
  CART_MINICART_FAILURE: 'CART_MINICART_FAILURE',
  CART_MINICART_SUCCESS: 'CART_MINICART_SUCCESS',
  CART_SENDLISTTOSTORE_ORDERVERIFY_SUCCESS_INELIGIBLE:
    'CART_SENDLISTTOSTORE_ORDERVERIFY_SUCCESS_INELIGIBLE',
  CART_VERIFY_FAILURE: 'CART_VERIFY_FAILURE',
  CART_VERIFY_REQUESTING: 'CART_VERIFY_REQUESTING',
  CART_VERIFY_SUCCESS: 'CART_VERIFY_SUCCESS',
  CHECKOUT_FAILURE: 'CHECKOUT_FAILURE',
  CHECKOUT_REQUESTING: 'CHECKOUT_REQUESTING',
  CHECKOUT_SUCCESS: 'CHECKOUT_SUCCESS',
  CHECKOUT_INVALID: 'CHECKOUT_INVALID',
  CHECKOUT_UNKNOWN: 'CHECKOUT_UNKNOWN',
  CHECKOUT_VERIFY_FAILURE: 'CHECKOUT_VERIFY_FAILURE',
  CHECKOUT_VERIFY_REQUESTING: 'CHECKOUT_VERIFY_REQUESTING',
  CHECKOUT_VERIFY_SUCCESS: 'CHECKOUT_VERIFY_SUCCESS',
  CHECKOUT_TEXT_SUBSCRIPTION: 'CHECKOUT_TEXT_SUBSCRIPTION',
  CHECKOUT_UPDATE_SUBSCRIPTIONS: 'CHECKOUT_UPDATE_SUBSCRIPTIONS',
  CLEAR_CART_PAGE_DATA: 'CLEAR_CART_PAGE_DATA',
  CLEAR_CHECKOUT_STORE: 'CLEAR_CHECKOUT_STORE',
  CLEAR_FETCH_REPAIR_GUIDES_SUCCESS: 'CLEAR_FETCH_REPAIR_GUIDES_SUCCESS',
  CLEAR_FORM_DATA: 'CLEAR_FORM_DATA',
  CLEAR_GIFT_CARD_ERROR_MSG: 'CLEAR_GIFT_CARD_ERROR_MSG',
  CLEAR_ORDER_DETAILS: 'CLEAR_ORDER_DETAILS',
  SAVE_CONTINUE_AS_GUEST: 'SAVE_CONTINUE_AS_GUEST',
  CLEAR_PRODUCT_FILTER_EVENTS: 'CLEAR_PRODUCT_FILTER_EVENTS',
  CLEAR_PRODUCT_FILTER_SELECTION: 'CLEAR_PRODUCT_FILTER_SELECTION',
  CLEAR_SEARCH_REDIRECT_TEXT: 'CLEAR_SEARCH_REDIRECT_TEXT',
  CLEAR_VALIDATE_REWARDS: 'CLEAR_VALIDATE_REWARDS',
  CLEAR_VALIDATE_QUESTIONS_REQUIRED: 'CLEAR_VALIDATE_QUESTIONS_REQUIRED',
  CLEAR_VEHICLE_BY_ID_DETAILS: 'CLEAR_VEHICLE_BY_ID_DETAILS',
  COMPLETE_FAILURE: 'COMPLETE_FAILURE',
  COMPLETE_REQUESTING: 'COMPLETE_REQUESTING',
  COMPLETE_SUCCESS: 'COMPLETE_SUCCESS',
  CONTACTUS_CLEAR_STATUS: 'CONTACTUS_CLEAR_STATUS',
  CONTACTUS_SUCCESS: 'CONTACTUS_SUCCESS',
  CREATE_ACCOUNT_FAILURE: 'CREATE_ACCOUNT_FAILURE',
  CREATE_ACCOUNT_REQUESTING: 'CREATE_ACCOUNT_REQUESTING',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_ERROR_CLEAR: 'CREATE_ACCOUNT_ERROR_CLEAR',
  CREATE_ACCOUNT_SHOW_FORM: 'CREATE_ACCOUNT_SHOW_FORM',
  ELEVAATEDATA_AVAILABLE_STATUS: 'ELEVAATEDATA_AVAILABLE_STATUS',
  EMPTY_DATA: 'EMPTY_DATA',
  EMPTY_CART_ITEMS: 'EMPTY_CART_ITEMS',
  ENTER_REWARDS_ID_FORM: 'ENTER_REWARDS_ID_FORM',
  FEDX_VALIDATION_FAILURE: 'FEDX_VALIDATION_FAILURE',
  BILLING_ADDRESSS_FAILURE: 'BILLING_ADDRESSS_FAILURE',
  BILLING_ADDRESSS_INVALID: 'BILLING_ADDRESSS_INVALID',
  FEDX_VALIDATION_EDIT_NOT_FOUND_ADDRESS: 'FEDX_VALIDATION_EDIT_NOT_FOUND_ADDRESS',
  FEDX_VALIDATION_NOT_FOUND_AND_WITHOUT_SUGGESTED_ADDRESS:
    'FEDX_VALIDATION_NOT_FOUND_AND_WITHOUT_SUGGESTED_ADDRESS',
  FEDX_VALIDATION_INVALID: 'FEDX_VALIDATION_INVALID',
  FEDX_VALIDATION_NOT_REQUIRED: 'FEDX_VALIDATION_NOT_REQUIRED',
  FEDX_VALIDATION_REQUESTING: 'FEDX_VALIDATION_REQUESTING',
  FEDX_VALIDATION_SUCCESS: 'FEDX_VALIDATION_SUCCESS',
  FETCH_REPAIR_GUIDE_CHAPTERS_FAILURE: 'FETCH_REPAIR_GUIDE_CHAPTERS_FAILURE',
  FETCH_REPAIR_GUIDE_CHAPTERS_REQUESTING: 'FETCH_REPAIR_GUIDE_CHAPTERS_REQUESTING',
  FETCH_REPAIR_GUIDE_CHAPTERS_SUCCESS: 'FETCH_REPAIR_GUIDE_CHAPTERS_SUCCESS',
  FETCH_REPAIR_GUIDE_CHAPTERS_INVALID: 'FETCH_REPAIR_GUIDE_CHAPTERS_INVALID',
  HIDE_POPUP: 'HIDE_POPUP',
  INITIAL_ADDRESS_DETAILS_FAILURE: 'INITIAL_ADDRESS_DETAILS_FAILURE',
  INITIAL_ADDRESS_DETAILS_REQUESTING: 'INITIAL_ADDRESS_DETAILS_REQUESTING',
  INITIAL_ADDRESS_DETAILS_SUCCESS: 'INITIAL_ADDRESS_DETAILS_SUCCESS',
  LINK_REWARDS_ACC: 'LINK_REWARDS_ACC',
  LINK_REWARDS_ACC_FORM: 'LINK_REWARDS_ACC_FORM',
  LITLEPAY_ERROR: 'LITLEPAY_ERROR',
  MY_PROFILE_PATCH_REQUESTING: 'MY_PROFILE_PATCH_REQUESTING',
  MY_PROFILE_PATCH_SUCCESS: 'MY_PROFILE_PATCH_SUCCESS',
  MY_PROFILE_INVALID: 'MY_PROFILE_INVALID',
  MY_PROFILE_PATCH_INVALID: 'MY_PROFILE_PATCH_INVALID',
  MY_PROFILE_STATES_INVALID: 'MY_PROFILE_STATES_INVALID',
  ORDER_DETAILS_CLEAR: 'ORDER_DETAILS_CLEAR',
  ORDER_DETAILS_FAILURE: 'ORDER_DETAILS_FAILURE',
  ORDER_DETAILS_INVALID: 'ORDER_DETAILS_INVALID',
  ORDER_DETAILS_REQUESTING: 'ORDER_DETAILS_REQUESTING',
  ORDER_DETAILS_SUCCESS: 'ORDER_DETAILS_SUCCESS',
  INITIAL_PART_STATUS: 'INITIAL_PART_STATUS',
  PAYMENT_OPTION_FAILURE: 'PAYMENT_OPTION_FAILURE',
  PAYMENT_OPTION_REQUESTING: 'PAYMENT_OPTION_REQUESTING',
  PAYMENT_OPTION_SUCCESS: 'PAYMENT_OPTION_SUCCESS',
  PAYPAL_INDICATOR_UPDATE: 'PAYPAL_INDICATOR_UPDATE',
  SAVE_CART_ITEM_STATE: 'SAVE_CART_ITEM_STATE',
  BULK_SAVE_CART_ITEM_STATE: 'BULK_SAVE_CART_ITEM_STATE',
  RESET_CART_ITEMS_STATE: 'RESET_CART_ITEMS_STATE',
  SET_CAN_SHOW_TOAST_NOTIFICATION: 'SET_CAN_SHOW_TOAST_NOTIFICATION',
  SHOW_LIST_OF_PROFILES: 'SHOW_LIST_OF_PROFILES',
  SKU_DETAILS_REQUESTING: 'SKU_DETAILS_REQUESTING',
  SKU_DETAILS_SUCCESS: 'SKU_DETAILS_SUCCESS',
  SKU_DETAILS_FAILURE: 'SKU_DETAILS_FAILURE',
  SKU_DETAILS_INVALID: 'SKU_DETAILS_INVALID',
  PROMOCODE_APPLY_FAILURE: 'PROMOCODE_APPLY_FAILURE',
  PROMOCODE_APPLY_REQUESTING: 'PROMOCODE_APPLY_REQUESTING',
  PROMOCODE_APPLY_SUCCESS: 'PROMOCODE_APPLY_SUCCESS',
  PROMOCODE_REMOVE_FAILURE: 'PROMOCODE_REMOVE_FAILURE',
  PROMOCODE_REMOVE_REQUESTING: 'PROMOCODE_REMOVE_REQUESTING',
  PROMOCODE_REMOVE_SUCCESS: 'PROMOCODE_REMOVE_SUCCESS',
  REMOVE_ORDERITEM_FAILURE: 'REMOVE_ORDERITEM_FAILURE',
  REMOVE_ORDERITEM_SUCCESS: 'REMOVE_ORDERITEM_SUCCESS',
  REQUEST_CREDIT_IDLE: 'REQUEST_CREDIT_IDLE',
  REQUEST_CREDIT_FAILURE: 'REQUEST_CREDIT_FAILURE',
  REQUEST_CREDIT_REQUESTING: 'REQUEST_CREDIT_REQUESTING',
  REQUEST_CREDIT_SUCCESS: 'REQUEST_CREDIT_SUCCESS',
  RESET_COMPLETE_PURCHASE_ERROR_MSG: 'RESET_COMPLETE_PURCHASE_ERROR_MSG',
  RESET_ENTERED_ADDRESS: 'RESET_ENTERED_ADDRESS',
  RESET_PROMO_CART: 'RESET_PROMO_CART',
  REWARDS_PAGE_FAILURE: 'REWARDS_PAGE_FAILURE',
  REWARDS_PAGE_INVALID: 'REWARDS_PAGE_INVALID',
  REWARDS_PAGE_REQUESTING: 'REWARDS_PAGE_REQUESTING',
  REWARDS_PAGE_SUCCESS: 'REWARDS_PAGE_SUCCESS',
  SAVE_ADDRESS_TO_PROFILE: 'SAVE_ADDRESS_TO_PROFILE',
  SEARCH_CRITERIA_FOUND: 'SEARCH_CRITERIA_FOUND',
  SEARCH_REDIRECT_TEXT: 'SEARCH_REDIRECT_TEXT',
  SEARCH_SUCCESS: 'SEARCH_SUCCESS',
  SET_APP_BANNER_SHOWN: 'SET_APP_BANNER_SHOWN',
  SET_ADDRESS_STATE: 'SET_ADDRESS_STATE',
  SET_BILLING_DEFAULT: 'SET_BILLING_DEFAULT',
  SET_CARD_TYPE: 'SET_CARD_TYPE',
  SET_CHAT_AGENT_STATUS: 'SET_CHAT_AGENT_STATUS',
  SET_CHECKOUT_BUTTON: 'SET_CHECKOUT_BUTTON',
  SET_DEVICE_INFO: 'SET_DEVICE_INFO',
  SET_FETCHING_LOCATION_STATUS: 'SET_FETCHING_LOCATION_STATUS',
  SET_FOOTER_STATE: 'SET_FOOTER_STATE',
  SET_HEADER_STATE: 'SET_HEADER_STATE',
  SET_LAYOUT: 'SET_LAYOUT',
  SET_SELECTED_NULL_CARTRIDGE: 'SET_SELECTED_NULL_CARTRIDGE',
  SET_PRODUCT_FILTER_EVENTS: 'SET_PRODUCT_FILTER_EVENTS',
  SET_FILTER_SCROLL: 'SET_FILTER_SCROLL',
  SET_FILTER_SCROLL_BRAND: 'SET_FILTER_SCROLL_BRAND',
  SET_ITEMS_PER_PAGE_OR_SORT_BY_FOCUS: 'SET_ITEMS_PER_PAGE_OR_SORT_BY_FOCUS',
  SET_ORDER_STATUS: 'SET_ORDER_STATUS',
  SET_SECONDARY_HEADER_FOOTER_STATE: 'SET_SECONDARY_HEADER_FOOTER_STATE',
  SET_SHIPPING_DEFAULT: 'SET_SHIPPING_DEFAULT',
  SET_UPDATE_FAIL: 'SET_UPDATE_FAIL',
  SET_PAGE_SPECIFIC_UTAG_DATA: 'SET_PAGE_SPECIFIC_UTAG_DATA',
  SHOW_BILLING_FORM_DEFAULT: 'SHOW_BILLING_FORM_DEFAULT',
  SHOW_CREATE_ACCOUNT_CONFIRMATION: 'SHOW_CREATE_ACCOUNT_CONFIRMATION',
  SHOW_REWARD_CONFIRMATION: 'SHOW_REWARD_CONFIRMATION',
  SHOW_REWARD_CONFIRMATION_CONGRATS: 'SHOW_REWARD_CONFIRMATION_CONGRATS',
  SHOW_REWARD_SIGNIN: 'SHOW_REWARD_SIGNIN',
  SHOW_SHIPPING_FORM_DEFAULT: 'SHOW_SHIPPING_FORM_DEFAULT',
  SIGN_ME_UP_FORM: 'SIGN_ME_UP_FORM',
  // TODO: fix typo across all places where it's used
  SR_VALIDATION_FAILURE: 'SR_VALIDATION_FAILURE',
  SR_VALIDATION_SUCCESS: 'SR_VALIDATION_SUCCESS',
  STATES_FAILURE: 'STATES_FAILURE',
  STATES_REQUESTING: 'STATES_REQUESTING',
  STATES_SUCCESS: 'STATES_SUCCESS',
  STORE_DETAILS_SUCCESS: 'STORE_DETAILS_SUCCESS',
  STORECHANGE_FAILURE: 'STORECHANGE_FAILURE',
  STORECHANGE_SUCCESS: 'STORECHANGE_SUCCESS',
  SUBMITFORGOT_FAILURE: 'SUBMITFORGOT_FAILURE',
  SUBMITFORGOT_REQUESTING: 'SUBMITFORGOT_REQUESTING',
  SUBMITFORGOT_RESET: 'SUBMITFORGOT_RESET',
  SUBMITFORGOT_SUCCESS: 'SUBMITFORGOT_SUCCESS',
  SUBMITRESET_FAILURE: 'SUBMITRESET_FAILURE',
  SUBMITRESET_REQUESTING: 'SUBMITRESET_REQUESTING',
  SUBMITRESET_SUCCESS: 'SUBMITRESET_SUCCESS',
  UPDATE_AUTHENTICATION: 'UPDATE_AUTHENTICATION',
  UPDATE_DELIVERY_FAILURE: 'UPDATE_DELIVERY_FAILURE',
  UPDATE_DELIVERY_REQUESTING: 'UPDATE_DELIVERY_REQUESTING',
  UPDATE_DELIVERY_SUCCESS: 'UPDATE_DELIVERY_SUCCESS',
  UPDATE_ORDERITEM_REQUESTING: 'UPDATE_ORDERITEM_REQUESTING',
  UPDATE_ORDERITEM_FAILURE: 'UPDATE_ORDERITEM_FAILURE',
  UPDATE_ORDERITEM_SUCCESS: 'UPDATE_ORDERITEM_SUCCESS',
  UPDATE_ITEM_QUANTITY_SUCCESS: 'UPDATE_ITEM_QUANTITY_SUCCESS',
  UPDATE_PROFILE_ADDRESS: 'UPDATE_PROFILE_ADDRESS',
  UPDATE_SHIPPING_ADDRESS_AVAILABLE: 'UPDATE_SHIPPING_ADDRESS_AVAILABLE',
  VALIDATE_REWARD_QUESTIONS: 'VALIDATE_REWARD_QUESTIONS',
  VALIDATE_REWARDS_FAILURE: 'VALIDATE_REWARDS_FAILURE',
  VALIDATE_REWARDS_INVALID: 'VALIDATE_REWARDS_INVALID',
  VALIDATE_REWARDS_REQUESTING: 'VALIDATE_REWARDS_REQUESTING',
  VALIDATE_REWARDS_SUCCESS: 'VALIDATE_REWARDS_SUCCESS',
  FETCH_REPAIRGUIDS_CHAAPTERS_REQUESTING: 'FETCH_REPAIRGUIDS_CHAAPTERS_REQUESTING',
  SET_PAYMENT_STATUS: 'SET_PAYMENT_STATUS',
  SET_PAYMENT_STATUS_SUCCESS: 'SET_PAYMENT_STATUS_SUCCESS',
  CART_SENDLISTTOSTORE_ORDERVERIFY_INVALID: 'CART_SENDLISTTOSTORE_ORDERVERIFY_INVALID',
  ANALYTICS_DATALAYER_REQUESTING: 'ANALYTICS_DATALAYER_REQUESTING',
  ANALYTICS_DATALAYER_INVALID: 'ANALYTICS_DATALAYER_INVALID',
  ANALYTICS_DATALAYER_FAILURE: 'ANALYTICS_DATALAYER_FAILURE',
  RESETPASSWORDLINK_FAILURE: 'RESETPASSWORDLINK_FAILURE',
  RESETPASSWORDLINK_SUCCESS: 'RESETPASSWORDLINK_SUCCESS',
  LOG_OUT: '@@configureStore/LOGOUT',
  UTAG_AVAILABLE_STATUS: 'UTAG_AVAILABLE_STATUS',
  SET_LOADING_FOR_PRODUCT: 'SET_LOADING_FOR_PRODUCT',
  HEADER_SUCCESS: 'HEADER_SUCCESS',
  IS_ELEVAATE_DATA_DEFINED_SUCCESS: 'IS_ELEVAATE_DATA_DEFINED_SUCCESS',
  SET_EMAIL_AND_PHONE: 'SET_EMAIL_AND_PHONE',
  SAVE_GOOGLE_CAPTCHA_TOKEN: 'SAVE_GOOGLE_CAPTCHA_TOKEN',
  RESET_GOOGLE_CAPTCHA_TOKEN: 'RESET_GOOGLE_CAPTCHA_TOKEN',
  SET_IS_AUTOFILL: 'SET_IS_AUTOFILL',
  UPDATE_ORDER_ADDRESS_REQUESTING: 'UPDATE_ORDER_ADDRESS_REQUESTING',
  SET_SHOULD_SHOW_ANIMATION: 'SET_SHOULD_SHOW_ANIMATION',
};

export default actionTypes;
