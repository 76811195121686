/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '@az/starc-ui';
import cx from 'classnames';
import { type AppData, type ReduxState } from '@/types';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';
import styles from './styles.module.scss';
import { SmartLink } from '@/utils/smartLink';
import { routePaths } from '@/constants/routePaths';
import { useRouter } from 'next/router';
import { sessionStorage } from '@/utils/sessionStorage';
import { SignInIcon } from './SignIngIcon';
import { Button } from '@/components/Button';
import { useStoreDetailsData } from '../../../api/getStoreDetails';
import { countryCodes } from '@/constants/locale';
import dynamic from 'next/dynamic';
import { Spinner } from '@/components/AZCustomComponent/Spinner';
import { setCookie } from '@/utils/cookie';

const LazyMobileHeaderAccountModalBody = dynamic(
  () => import('./MobileHeaderAccountModalBody').then((mod) => mod.MobileHeaderAccountModalBody),
  {
    loading: () => (
      <div className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    ),
  }
);

const LazyMobileHeaderSignInModalBody = dynamic(
  () => import('./MobileHeaderSignInModalBody').then((mod) => mod.MobileHeaderSignInModalBody),
  {
    loading: () => (
      <div className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    ),
  }
);

const labelMap = {
  lblSignIn: 'mf_homepage_header_signin',
  lblAccount: 'label_header_signin_feature_account',
};

type MobileHeaderSignInProps = {
  isFixedHeader?: boolean;
};

export function MobileHeaderSignIn({ isFixedHeader = false }: MobileHeaderSignInProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { userAuthenticated } = useSelector<ReduxState>(({ appData }) => appData) as AppData;
  const { lblAccount, lblSignIn } = useLabels(labelMap);
  const locale = useLocale();
  const { data: storeData } = useStoreDetailsData();
  const isBopusEnabled = storeData?.bopusEnabled ?? false;
  const isMX = locale === countryCodes.mx;
  const isBopusMexicoEnabled = isMX && isBopusEnabled;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const router = useRouter();

  const mainLabel = userAuthenticated ? lblAccount : lblSignIn;
  return (
    <>
      {showLoading ? <ProgressModal /> : null}
      <div className={styles.signInMobileFeature}>
        {userAuthenticated ? (
          <Button
            data-testid="signin-header-icon"
            onClick={openModal}
            aria-label={mainLabel}
            className={styles.signInAuthenticated}
          >
            <SignInIcon
              isFixedHeader={isFixedHeader}
              mainLabel={mainLabel}
              className={styles.signInAuthenticated}
            />
          </Button>
        ) : (
          <div className={styles.signInMobileFeature}>
            {isBopusMexicoEnabled ? (
              <Button
                data-testid="signin-header-icon"
                onClick={openModal}
                aria-label={mainLabel}
                className={styles.signInAuthenticated}
              >
                <SignInIcon
                  isFixedHeader={isFixedHeader}
                  mainLabel={mainLabel}
                  className={styles.signInAuthenticated}
                />
              </Button>
            ) : (
              <SmartLink
                rel="nofollow"
                to={routePaths.signIn}
                data-testid="signin-header-icon"
                aria-label={mainLabel}
                onClick={() => {
                  setCookie('loginInteractionMethod', 'Header');
                  sessionStorage.setItem('DropDownRendered', 'true');
                  {
                    openModal;
                  }
                  if (router.pathname === routePaths.cart) {
                    sessionStorage.setItem('fromSignin', router.pathname);
                  }
                }}
              >
                <Button
                  data-testid="signin-header-icon"
                  aria-label={mainLabel}
                  className={styles.signInAuthenticated}
                  onClick={() => {
                    sessionStorage.setItem('DropDownRendered', 'true');
                    {
                      openModal;
                    }
                    if (router.pathname === routePaths.cart) {
                      sessionStorage.setItem('fromSignin', router.pathname);
                    }
                  }}
                >
                  <SignInIcon
                    isFixedHeader={isFixedHeader}
                    mainLabel={mainLabel}
                    className={cx(styles.signInUnAuthenticated)}
                  />
                </Button>
              </SmartLink>
            )}
          </div>
        )}
      </div>
      <Modal
        size="full"
        open={isModalOpen}
        onClose={closeModal}
        hasCloseButton
        className={styles.modalContent}
      >
        {userAuthenticated ? (
          <LazyMobileHeaderAccountModalBody
            closeModal={closeModal}
            setShowLoading={setShowLoading}
          />
        ) : (
          <LazyMobileHeaderSignInModalBody closeModal={closeModal} />
        )}
      </Modal>
    </>
  );
}
