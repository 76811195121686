/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Label } from '@/features/i18n';

export function validateMaxLength(value: string, max: number) {
  if (!value) {
    return value;
  }

  if (value.length >= max) {
    return <Label label="error_max_character_limit" />;
  }

  return null;
}
