/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const API_URLS = {
  ADD_CONFIG_ITEM: '/ecomm/b2c/v1/shoppingCart/addConfigItem',
  ADD_SERVICE_HISTORY: '/ecomm/b2c/v1/currentUser/vehicles/serviceHistory',
  ADD_TO_CART: '/ecomm/b2c/v1/order/item',
  ADDRESS_VERIFY: '/ecomm/b2c/v1/utility/address/verify',
  CANCEL_ORDER: '/ecomm/b2c/v1/order/cancelOrder',
  CANCEL_ORDER_SHIPPING_GROUP: '/ecomm/b2c/v1/order/cancelOrderShippingGroup',
  CART_DETAILS: '/ecomm/b2c/v1/shoppingCart/cartDetails',
  CERTONA_PRICING: '/ecomm/b2c/v1/browse/skus/price/',
  CLAIM_COUPON: '/ecomm/b2c/v1/coupons/claimCoupon',
  CONTACT_US: '/ecomm/b2c/v1/currentUser/contactUs',
  CURRENT_USER: '/ecomm/b2c/v1/currentUser',
  CURRENT_VEHICLE: '/ecomm/b2c/v1/currentUser/vehicle',
  CURRENT_SEARCH_REWARDS_LINKING_ID: '/ecomm/b2c/v1/currentUser/search',
  CURRENT_VEHICLES: '/ecomm/b2c/v1/currentUser/vehicles',
  FORGOT_PASSWORD: '/ecomm/b2c/v1/currentUser/forgotPassword',
  GENESYS_CHAT_AGENTS_AVAILABILITY:
    '/integrations/genesys/v1/StatLookup/stats/CurrentGroupAgentTargetState',
  GEO_SEARCH: '/locations/geosearch',
  LOGOUT: '/ecomm/b2c/v1/currentUser/logout',
  MESSAGE_CONTENT: '/ecomm/b2c/v1/message/messageContent',
  MINI_CART: '/ecomm/b2c/v1/order/mini-cart',
  LOCATION_SEARCH: '/locations',
  NEW_CHAT_AVAILABLE_ENDPOINT_FALLBACK:
    'https://chat01.wx046.webexcce.com/system/egain/chat/entrypoint/capacity/1008',
  ORDER_ADDRESS: '/ecomm/b2c/v1/order/address',
  ORDER_ADDRESS_V2: '/ecomm/b2c/order/v2/address',
  ORDER_COMMIT: '/ecomm/b2c/v1/order/commit',
  ORDER_COMMIT_V3: '/ecomm/b2c/order/v3/commit',
  ORDER_CONFIRMATION: '/ecomm/b2c/v1/checkout/page/orderConfirmation',
  ORDER_DETAILS_V2: '/ecomm/b2c/order/v2/details',
  ORDER_DETAILS_V4: '/ecomm/b2c/order/v4/details',
  ORDER_HISTORY: '/ecomm/b2c/v1/currentUser/orders',
  ORDER_HISTORY_V2: '/ecomm/b2c/v2/currentUser/orders',
  ORDER_ITEM: '/ecomm/b2c/v1/order/item',
  ADD_DEAL_TO_CART: '/ecomm/b2c/order/v2/skus',
  ORDER_LOOKUP: '/ecomm/b2c/v1/order/lookup',
  ORDER_PAYMENT: '/ecomm/b2c/v1/order/payment',
  ORDER_SHIPMENT_V2: '/ecomm/b2c/order/v2/shipment',
  PAYMENT_OPTIONS: '/ecomm/b2c/v1/order/payment/options',
  PAYPAL_AUTHENTICATE: '/ecomm/b2c/v1/order/paypal/authenticate',
  PAYPAL_LOOKUP: '/ecomm/b2c/v1/order/paypal/lookup',
  PREFERRED_STORE: '/ecomm/b2c/v1/store/preferredStore',
  QUICK_REGISTER: '/ecomm/b2c/v1/currentUser/quickRegister',
  QUICK_SUBSCRIPTION: '/ecomm/b2c/v1/currentUser/quickSubscription',
  REPAIR_GUIDES_CHAPTER_INFO: '/ecomm/b2c/v1/currentUser/repairGuideChapterInfo',
  RESET_PASSWORD: '/ecomm/b2c/v1/currentUser/resetPassword',
  REVOKE_COUPON: '/ecomm/b2c/v1/coupons/revokeCoupon',
  REWARDS: '/ecomm/b2c/v1/checkout/payments/rewards',
  REWARDS_EMAIL: '/ecomm/b2c/v1/currentUser/rewardsCard/email',
  REWARDS_ID_VERIFY: '/ecomm/b2c/v1/order/verifyRewardsId',
  SHIPMENT_OPTIONS: '/ecomm/b2c/v1/order/shipment/options',
  SHIPMENT_OPTIONS_V2: '/ecomm/b2c/order/v2/shipment/options',
  SHIPMENT_OPTIONS_POST: '/ecomm/b2c/order/v2/shipment-options',
  SHIPPING_ADDRESS: '/ecomm/b2c/v1/checkout/shippingAddress',
  SHOP_DEALS_BY_CATEGORY: '/ecomm/b2c/v1/deals/shopDealsByCategory',
  SISTER_STORES: '/ecomm/b2c/v1/store/sisterStores',
  SITE_GLOBAL_CONFIGS: '/ecomm/b2c/v1/globalConfigs/siteGlobalConfigs',
  START_SESSION: '/ecomm/b2c/v1/utility/startSession',
  STORE_CIDS: '/ecomm/b2c/v1/store/cids',
  STORE_LIST: '/ecomm/b2c/v1/order/store-list ',
  STORE_REBATES: '/ecomm/b2c/v1/rebates/storeRebates',
  SWAP_COMMERCE_ITEM: '/ecomm/b2c/v1/shoppingCart/swapCommerceItem',
  UPDATE_PREFERRED_VEHICLE: '/ecomm/b2c/v1/ymme/updatePreferredVehicle',
  VALIDATE_REWARDS_ACCOUNT: '/ecomm/b2c/v1/currentUser/rewards/validateRewardsAccount',
  VALIDATE_SECURITY_QUESTION: '/ecomm/b2c/v1/currentUser/rewards/validateSecurityQuestion',
  VALIDATE_SR_TOKEN: '/ecomm/b2c/v1/shoprunner/validateSRToken',
  VEHICLE_REPAIR_GUIDE: '/ecomm/b2c/v1/currentUser/vehicleRepairGuide',
  VEHICLES_DETAILS: '/ecomm/b2c/v1/currentUser/vehicles/details',
  VERIFY_FOR_CHECKOUT: '/ecomm/b2c/v1/order/verifyForCheckout',
  VERIFY_FOR_SEND_TO_STORE_LIST: '/ecomm/b2c/v1/order/verifyForSendToStoreList',
  CUSTOMER_LOOKUP: '/ecomm/b2c/customer/v1/search',
  TOP_SMM_SMMY_REVIEWS: '/ecomm/b2c/v1/browse/top-reviews',
  AFFINITY_RELATED_PARTS: '/ecomm/b2c/v1/browse/affinity/related-parts',
  BRANDS: '/ecomm/b2c/v1/browse/brands',
  WARRANTIES: '/bff/warranty-wrapper/v1/warranties',
  APPLE_AUTHORIZE_MERCHANT: '/ecomm/b2c/order/v2/applepay/session',
};
