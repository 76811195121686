/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { LottiePlayer, RendererType } from 'lottie-web';
import { useEffect, useRef, useState, memo } from 'react';
import cx from 'classnames';
import { setShouldShowAnimation } from '@/actions/app';
import { useDispatch } from '@/hooks/redux/useDispatch';

interface Props {
  src: string;
  loop?: boolean | number;
  renderer?: RendererType;
  autoplay?: boolean;
  classes?: {
    [key: string]: string;
  };
}

const LottieAnimationComponent = ({
  src,
  loop = true,
  renderer,
  classes = {},
  autoplay = true,
}: Props) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement | null>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import('lottie-web/build/player/lottie_light').then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: renderer || 'svg',
        loop: loop,
        autoplay: autoplay,
        path: src,
      });

      animation.addEventListener('complete', () => {
        dispatch(setShouldShowAnimation(false));
      });

      return () => {
        if (typeof loop === 'number') {
          animation.play();
          return;
        }
        animation.play();
      };
    }
  }, [lottie, autoplay, loop, renderer, src, dispatch]);

  return (
    <div
      className={cx({
        [classes.root || '']: classes.root,
      })}
      ref={ref}
    ></div>
  );
};

export const LottieAnimation = memo(LottieAnimationComponent);
