/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getKiboDecisionFlag, useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import { type QueryClient } from '@tanstack/react-query';

export const useAcesFlags = () => {
  const acesEnabled = useMonetateDecisionFlag('ACES_ENABLED');
  const acesTransitionPhase = useMonetateDecisionFlag('TRANSITION_PHASE');
  const partsProFlag = useMonetateDecisionFlag('ACES_PARTS_PRO_FLAG');
  const acesNonVehicleEndpointsEnabled = useMonetateDecisionFlag('acesNonVehicleEndpointsEnabled');

  return {
    acesEnabled,
    acesTransitionPhase,
    partsProFlag,
    acesNonVehicleEndpointsEnabled,
  };
};

export const getAcesFlag = (queryClient: QueryClient | undefined) => {
  if (!queryClient) {
    throw new Error('Global config is undefined');
  }
  const acesEnabled = getKiboDecisionFlag(queryClient, 'ACES_ENABLED');
  const acesTransitionPhase = getKiboDecisionFlag(queryClient, 'TRANSITION_PHASE');
  const partsProFlag = getKiboDecisionFlag(queryClient, 'ACES_PARTS_PRO_FLAG');
  const acesNonVehicleEndpointsEnabled = getKiboDecisionFlag(
    queryClient,
    'acesNonVehicleEndpointsEnabled'
  );

  return {
    acesEnabled,
    acesTransitionPhase,
    partsProFlag,
    acesNonVehicleEndpointsEnabled,
  };
};
