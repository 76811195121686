/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getBaseSchemaUrl } from '../utils/common';
import { countryCodes } from '@/constants/locale';
import type { Locale } from '@/types/i18n';

export default {
  global: {
    apiDomainUrl: 'https://www.autozone.com',
  },
  app: {
    title: 'AutoZone',
    titleTemplate: 'AutoZone.com | %s',
    meta: [
      {
        name: 'description',
        content:
          'AutoZone is the leading auto parts retailer.You will always find the best replacement parts,aftermarket accessories for cars,trucks and SUVs online or In-store.Get yours today!',
      },
    ],
    scriptUS: [
      {
        type: 'application/ld+json',
        innerHTML: `{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "AutoZone",
          "url": "${getBaseSchemaUrl(countryCodes.us as Locale)}",
          "logo": "https://www.autozone.com/images/az-logo-full.svg",
          "contactPoint" : [
              {
                "@type" : "ContactPoint",
                "telephone" : "+1-800-288-6966",
                "contactType" : ["Sales","Customer Service"],
                "contactOption" : "TollFree",
                "areaServed" : "US",
                "availableLanguage" : ["English", "Spanish"]
              }
            ],
          "sameAs" : [
            "https://en.wikipedia.org/wiki/AutoZone",
            "https://www.facebook.com/autozone",
            "https://twitter.com/autozone",
            "https://www.youtube.com/user/AutoZone"
          ]
        }`,
      },
      {
        type: 'application/ld+json',
        innerHTML: `{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "${getBaseSchemaUrl(countryCodes.us as Locale)}",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://www.autozone.com/searchresult?searchText={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }`,
      },
    ],
    scriptMX: [
      {
        type: 'application/ld+json',
        innerHTML: `{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "AutoZone Mexico",
          "url": "${getBaseSchemaUrl(countryCodes.mx as Locale)}",
          "logo": "https://www.autozone.com.mx/images/logoautozonemx.svg",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+52-800-911-3456",
            "contactType": "customer service",
            "areaServed": "MX",
            "availableLanguage": ["Spanish", "English"],
            "sameAs": [
              "https://www.facebook.com/AutozoneMexico",
              "https://twitter.com/autozonemexico",
              "https://www.instagram.com/autozonemexico/"
            ]
          }
        }`,
      },
      {
        type: 'application/ld+json',
        innerHTML: `{
          "@context": "https://schema.org",
          "@type": "Website",
          "url": "${getBaseSchemaUrl(countryCodes.mx as Locale)}",
          "name": "AutoZone Mexico",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://www.autozone.com.mx/searchresult?searchText={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }`,
      },
      {
        type: 'application/ld+json',
        innerHTML: `{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            { "@type": "ListItem", "position": 1, "name": "Inicio", "item": "https://www.autozone.com.mx/" },
            { "@type": "ListItem", "position": 2, "name": "Accesorios", "item": "https://www.autozone.com.mx/accesorios-interiores" }
          ]
        }`,
      },
      {
        type: 'application/ld+json',
        innerHTML: `{
          "@context": "https://schema.org",
          "@type": "AggregateRating",
          "itemReviewed": { "@type": "Organization", "name": "AutoZone Mexico"},
          "ratingValue": "4.6",
          "reviewCount": "338"
        }`,
      },
    ],
  },
};
