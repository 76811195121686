/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { yextCompleteURL } from '@/config/serviceAPI';
import { countryCodes } from '@/constants/locale';
import { countryNames } from '@/features/i18n/constants/countryNames';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import type { GetSearchStoreAPIOptions, SearchStoreMutationOptions } from '../interface/interface';
import type { YextGeoSearchResponse } from '../interface/yext-types';
import { useFeatureFlag } from '@/features/globalConfig';

const version = '20180731';

const getSearchStore = async ({
  location,
  country,
  countryCode,
  limit,
  radius,
  yextURL,
  yextAPIKey,
  includeFilters,
}: GetSearchStoreAPIOptions) => {
  if (!yextURL) {
    throw new Error(`YEXT_URL is missing in globalConfig.`);
  }

  if (!yextAPIKey) {
    throw new Error(`YEXT_API_KEY is missing in globalConfig.`);
  }

  const filters = [
    {
      countryCode: {
        includes: [countryCode, countryCodes.vi],
      },
    },
  ];

  if (country !== countryNames['es-MX']) {
    filters[0].countryCode.includes.push(countryCodes.pr);
  }

  /**
   * Yext API reference:
   * https://hitchhikers.yext.com/docs/liveapis/knowledgegraphliveapi/locations/#operation/geoSearch
   */

  const URL_GEOPATH = yextCompleteURL(yextURL, 'yextGeoSearch');
  const response = await axios.get<YextGeoSearchResponse>(URL_GEOPATH, {
    params: {
      limit,
      radius,
      location: location.join(','),
      filters: includeFilters ? JSON.stringify(filters) : null,
      api_key: yextAPIKey,
      v: version,
    },
    withCredentials: false,
    headers: {
      'X-Requested-By': 'MF',
    },
  });

  return selector(response.data);
};

const selector = (data: YextGeoSearchResponse): YextGeoSearchResponse => data;

export const useSearchStoreMutation = () => {
  const yextURL = useFeatureFlag('YEXT_URL');
  const yextAPIKey = useFeatureFlag('YEXT_API_KEY');
  const storeSearchResultsLimit = useFeatureFlag('STORE_SEARCH_RESULTS_LIMIT') || '10';
  const storeSearchRadius = useFeatureFlag('STORE_SEARCH_RADIUS') || '150';

  return useMutation({
    mutationFn: ({ limit, radius, ...options }: SearchStoreMutationOptions) =>
      getSearchStore({
        ...options,
        yextURL,
        yextAPIKey,
        limit: limit ?? storeSearchResultsLimit,
        radius: radius ?? storeSearchRadius,
      }),
  });
};
