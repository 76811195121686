/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ProductRouteQueryParamsType, ShelfRouteQueryParamsType } from '../types/filterTypes';

export const acceptableShelfQueryParams: ShelfRouteQueryParamsType = {
  seourl: true,
  searchText: true,
  pageNumber: true,
  filters: true,
  vehicleId: true,
  filterByKeyWord: true,
  fromString: true,
  isIgnoreVehicle: true,
  isSearchByPartNumber: true,
  storeId: true,
  rrec: true,
  recsPerPage: true,
  minPrice: true,
  maxPrice: true,
  sort: true,
  facet: true,
  intcmp: true,
  partNumberSearch: true,
  redirectUrl: true,
};

export const acceptableProductQueryParams: ProductRouteQueryParamsType = {
  seourl: true,
  make: true,
  model: true,
  year: true,
  fromString: true,
  searchText: true,
  rrec: true,
  vehicleId: true,
};
