/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import styles from './signinIcon.module.scss';
import azCommonStyles from '@/theme/globals.module.scss';
import cx from 'classnames';
import { SignInAnimation } from './SignInAnimation';

type SignInIconProps = {
  isFixedHeader?: boolean;
  mainLabel: string;
  className: string;
};

export function SignInIcon({ mainLabel, isFixedHeader = false, className }: SignInIconProps) {
  return (
    <div
      className={cx({
        [styles.fixedSignInMobileIconAndLabel]: isFixedHeader,
        [className]: className,
      })}
    >
      <SignInAnimation isFixedHeader={isFixedHeader} />
      <span className={cx(azCommonStyles['az-caption'], styles.signInMobileLabel)}>
        {mainLabel}
      </span>
    </div>
  );
}
