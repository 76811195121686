/**
 * Copyright 2019-2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import Script from 'next/script';
import { useSelector } from 'react-redux';
import root from 'window-or-global';
import Head from 'next/head';
import cx from 'classnames';
import { Grid } from '../Grid';
import { useRouter } from 'next/router';
import config from '../../config/nonSEOData';
import homePageMeta from '../../config/homePageMeta';
import { routePaths } from '../../constants/routePaths';
import { Header } from '@/features/header/components/Header';
import { HeaderSecondary } from '@/features/header/components/HeaderSecondary';
import { BrazilHeader } from '@/features/header/components/BrazilHeader/BrazilHeader';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { ChatSlider, useChatAgentAvailabilityCheck, ProactiveChat } from '@/features/proactiveChat';
import { getPathnameFromAsPath, parseUrl } from '../../utils/urlHelpers';
import { getSHA256 } from '../../utils/getSHA256';
import ContentContainer from '../../components/AZCustomComponent/ContentContainer';
import AZSnackBar from '../../components/AZCustomComponent/AZSnackBar';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes, defaultStoreUS } from '@/constants/locale';
import usePrevious from '../../utils/usePrevious';
import { isRouteProtected } from '../../protectedRoutes';
import azCommonStyles from '../../theme/globals.module.scss';
import SmartAppBanner from '../AZCustomComponent/SmartAppBanner';
import { localStorage } from '../../utils/localStorage';
import { sessionStorage } from '../../utils/sessionStorage';
import { type ReduxState } from '../../types';
import { setFetchingLocationStatus, setChatAgentStatus } from './../../actions/app';
import styles from './styles.module.scss';
import { useUtagReady } from '@/utils/analytics/useUtagReady';
import { useAnalytics } from '@/utils/analytics/useAnalytics';
import { usePageSpecificData } from '@/utils/analytics/usePageSpecificData';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { analyticsCookie } from '@/constants/analytics/analyticsCookie';
import { analyticsGlobalVariables } from '@/utils/analytics/dataLayer/analyticsGlobalVariables';
import { Utag } from '@/components/Utag/Utag';
import { UtagSync } from '@/components/Utag/UtagSync';
import { getDecodedCookies, setCookie } from '@/utils/cookie';
import { Footer } from '@/features/footer/components/Footer';
import { BrazilFooter } from '@/features/footer/components/BrazilFooter';
import { FooterSecondary } from '@/features/footer/components/FooterSecondary';
import { FeatureFlag, useFeatureFlag, useGlobalConfig } from '@/features/globalConfig';
import { getStoredYmmeActionSource } from '@/features/ymme/utils/ymmeActionSourceHelper';
import { getStoredInteractionLocation } from '@/features/ymme/utils/ymmeLocationHelper';
import { ContactUsModal, NeedHelpTab } from '@/features/contactUs';
import { useGeolocation } from '@/hooks/useGeoLocation';
import { throttle } from '@/utils/throttle';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useAppState } from '@/stores/AppState';
import { useIsMobileQRExperience } from '@/hooks/useIsMobileQRExperience';
import { ResetPasswordMessageV2 } from '@/features/resetPassword/components/ResetPasswordMessageV2';
import { SignInFlowTypeContext } from '../SignInFlowTypeContext';
import dynamic from 'next/dynamic';
import { API_URLS } from '@/constants/apiUrls';
import { useBanner90DayContext } from '../Banner90DayContext';
import { useModalTCPromptContext } from '../ModalTCPromptContext';
import { getCustomScripts } from '@/utils/getCustomScripts';
import { useSearchStoreMutation } from '@/features/fulfillment/api/getSearchStore';
import { useSetPreferredStoreMutation } from '@/features/fulfillment/api/postPreferredStore';
import logger from '@/utils/logger';
import { useResetSignInNotifications } from '@/hooks/useResetSignInNotifications';
import { trackAddVehicle } from '@/utils/trackAddVehicle';
import { useAcesFlags } from '@/features/ymme/utils/acesFlags';

const LazyLCPMeasurements = dynamic(
  () =>
    import('@/features/performance/components/LCPMeasurements').then((mod) => mod.LCPMeasurements),
  { ssr: false }
);

const LazyBanner90Day = dynamic(
  () => import('@/components/Banner90Day').then((mod) => mod.Banner90Day),
  { ssr: false }
);

const LazyRewardsPilotProgramBanner = dynamic(
  () =>
    import('@/components/RewardsPilotProgramBanner').then((mod) => mod.RewardsPilotProgramBanner),
  { ssr: false }
);

const trackChangeVehicleSuccess = (vehicleData: any) => {
  clickTrack({
    eventType: 'changeVehicle',
    userVehicleCount: vehicleData.userVehicleCount,
    vehicleYear: vehicleData.vehicleYear,
    vehicleMake: vehicleData.vehicleMake,
    vehicleModel: vehicleData.vehicleModel,
    vehicleEngine: vehicleData.vehicleEngine,
    vehicleId: vehicleData.vehicleId,
    vehicle_interaction_location: vehicleData.interactionLocation,
  });
};

type AppProps = {
  children: any;
};

const labelMap = {
  lblShoppingFor: 'label_YMME_Body_YouAreNowShoppingFor',
  lblYouAreNowSignedIn: 'label_you_are_now_signed_in',
  lblScrollToSeeItemsThatFit: 'label_scroll_to_see_items_that_fit',
};

const App = ({ children }: AppProps) => {
  const { lblYouAreNowSignedIn, lblScrollToSeeItemsThatFit, lblShoppingFor } = useLabels(labelMap);
  const dispatch = useDispatch();
  const locale = useLocale();
  const router = useRouter();
  const { isMobileApp } = useAppState().state;
  const kiboEnabled = useFeatureFlag('KIBO_ENABLED') === 'true';
  const kiboScriptUrl = useFeatureFlag('KIBO_SCRIPT_URL');
  const { setPageSpecificData } = usePageSpecificData();
  const { data: globalConfig } = useGlobalConfig();
  const customScripts = useMemo(() => getCustomScripts(globalConfig), [globalConfig]);

  const isBrazilPhaseTwo = useFeatureFlag('BRAZIL_PHASE_TWO_ENABLED') === 'true';
  const isBrazil = locale === countryCodes.ptBr;

  const oneTrustDomainScriptUrl = useFeatureFlag('ONE_TRUST_DOMAIN_SCRIPT_URL');
  const oneTrustEnabled = useFeatureFlag('ONE_TRUST_ENABLED') === 'true';
  const oneTrustScriptUrl = useFeatureFlag('ONE_TRUST_SCRIPT_URL');

  const checkoutVersionEnableV3_us = useFeatureFlag('CHECKOUT_V3_ENABLED_US') === 'true';
  const checkoutVersionEnableV3_mx = useFeatureFlag('CHECKOUT_V3_ENABLED_MX') === 'true';
  const googleSiteVerificationKey = useFeatureFlag('GOOGLE_SITE_VERIFICATION_KEY');
  const isAppFlyerEnabled = useFeatureFlag('APPS_FLYER_ENABLED') === 'true';
  const isSmartAppBannerEnabled = useFeatureFlag('SMART_APP_BANNER_ENABLED') === 'true';
  const performanceLCPMeasurementsEnabled =
    useFeatureFlag('PERFORMANCE_LCP_MEASUREMENTS_ENABLED') === 'true';

  const oneTrustOrigin = useMemo(() => {
    if (oneTrustEnabled && oneTrustScriptUrl) {
      try {
        const url = new URL(oneTrustScriptUrl);
        return url.origin;
      } catch {
        logger.error({
          message: 'Error parsing OneTrust script URL',
          meta: { scriptURL: oneTrustScriptUrl },
        });
      }
    }
  }, [oneTrustEnabled, oneTrustScriptUrl]);

  const appData = useSelector(({ appData }: ReduxState) => appData);
  const canShowToastNotification = localStorage.getItem('canShowToastNotification');

  // Init analytics on every page
  // when login or cart component are absent (Brazil) it's not inited - root.utag_data absent
  useAnalytics();
  useEffect(() => {
    if (!appData.userAuthenticated) {
      setCookie('loginInteractionMethod', '');
    }
  }, [appData.userAuthenticated]);

  const {
    data: headerData,
    refetch: refetchHeaderData,
    dataUpdatedAt: headerDataUpdateAt,
  } = useHeaderData();
  const { acesEnabled } = useAcesFlags();
  const isAnonymousUser = headerData?.myAccountMap?.anonymous !== 'false';
  const rememberMe = headerData?.myAccountMap?.autoLogin === 'true';
  const cartData = headerData?.analyticsData;
  const vehicleName = headerData?.vehicleMap?.vehicleName || '';
  const preferredVehicleData = getPreferredVehicle(headerData);
  const vehicleId = preferredVehicleData?.catalogVehicleId;
  const vehicleCount = preferredVehicleData?.vehicleCount;
  const prevVehicleCount = usePrevious(preferredVehicleData?.vehicleCount);
  const { isUtagReady } = useUtagReady();
  const prevAnonymousStatus = usePrevious(headerData?.myAccountMap?.anonymous);
  const storeID = headerData?.storeNumber;
  const deviceType = useSelector(({ appData }: ReduxState) => appData.deviceType);
  const isABot = deviceType === 'bot';
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [shouldRerenderToTrackAddVehicle, setShouldRerenderToTrackAddVehicle] = useState(false);
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
  const isMobileQRExperience = useIsMobileQRExperience();
  const { openBanner: isBanner90Open } = useBanner90DayContext();
  const { modalOpen: isRewardsPilotProgramBannerOpen } = useModalTCPromptContext();
  const openSnackBar = (message: string) => {
    setSnackBarMessage(message);
    setShowSnackBar(true);
  };
  const { mutateAsync: searchStore } = useSearchStoreMutation();
  const { mutateAsync: setStore } = useSetPreferredStoreMutation();
  const justMounted = useRef(true);
  const prevVehicleId = usePrevious(vehicleId);
  const prevSessionVehicleId = sessionStorage.getItem('previousPreferredVehicleId');
  const prevHeaderDataUpdateAt = usePrevious(headerDataUpdateAt);
  const [isResetPasswordToastNotificationOpen, setIsResetPasswordToastNotificationOpen] =
    useState(false);
  const { flowType, isSignInModalOpened } = useContext(SignInFlowTypeContext);

  /**  CONTACT US MODAL AND CHAT SECTION */
  const contactUsModalEnabled = useFeatureFlag('CONTACT_US_MODAL_ENABLED') === 'true';
  const isV2ChatAgentAvailable = useFeatureFlag('CHECK_AGENT_AVAILABILITY_V2') === 'true';
  const chatAvailabilityCallEnabled =
    useFeatureFlag('GENESYS_AVAILABILITY_CALL_ENABLED') === 'true' && !isABot;
  const chatMobileEnabled = useFeatureFlag('CHAT_ON_MOBILE_ENABLED') === 'true';
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const isNewChatVendorEnabled = useFeatureFlag('ENABLE_NEW_CHAT_VENDOR') === 'true';

  // This is the old behavior to check chat agent availability wich makes API calls every X time  (V1)
  // TODO: delete in (CB-5641)
  useChatAgentAvailabilityCheck();

  const isMxSite = useLocale() === countryCodes.mx;

  const { NEW_CHAT_AVAILABLE_ENDPOINT_FALLBACK } = API_URLS;

  const NEW_CHAT_AVAILABLE_ENDPOINT =
    useFeatureFlag('NEW_CHAT_AVAILABLE_ENDPOINT') ?? NEW_CHAT_AVAILABLE_ENDPOINT_FALLBACK;

  const openContactUsModal = () => {
    setIsContactUsModalOpen(true);

    // This is the new behavior to check chat agent availability wich makes API calls on click of the contact us tab (V2)
    if (isV2ChatAgentAvailable && !isMxSite && chatAvailabilityCallEnabled) {
      if (matchesMobile && !chatMobileEnabled) {
        return;
      }
      const throttledCheck = throttle(() => {
        dispatch(
          setChatAgentStatus({
            isNewChatVendorEnabled: isNewChatVendorEnabled,
            NEW_CHAT_AVAILABLE_ENDPOINT: NEW_CHAT_AVAILABLE_ENDPOINT,
          })
        );
      }, 3000);
      throttledCheck();
    }
  };
  /**  END CONTACT US MODAL AND CHAT SECTION */

  const coordinates = useGeolocation();

  const preferredStoreFromURL = useRef<string>('');

  if (router.asPath.includes('spps.s') && !router.asPath.includes('new_store_set=true')) {
    const urlParams = new URLSearchParams(router.asPath.split('?')[1]);
    preferredStoreFromURL.current = urlParams.get('spps.s') || '';
  }

  const getVehicleAnalyticsData = (isAddByURL?: boolean) => {
    const vehicleData = headerData?.vehicleMap || {
      vehicleCount: '',
      make: '',
      model: '',
      engine: '',
      catalogVehicleId: '',
      year: '',
    };
    return {
      userVehicleCount: vehicleData.vehicleCount,
      vehicleMake: vehicleData.make,
      vehicleModel: vehicleData.model,
      vehicleEngine: vehicleData.engine,
      vehicleId: vehicleData.catalogVehicleId,
      vehicleYear: vehicleData.year,
      interactionLocation: isAddByURL ? 'URL' : getStoredInteractionLocation(),
      actionSource: getStoredYmmeActionSource(),
    };
  };

  const hashUserName = (userName: string) => {
    const hashedEId = getSHA256(userName.toLowerCase());
    hashedEId.then((hash) => {
      if (root.utag_data) {
        root.utag_data.hashedEId = hash;
      }

      setCookie(analyticsCookie.cookieHashedEId, hash);
    });
  };

  //Should be used only on cookie policy. Trigger function below to remove duplicate categories.
  function clearDuplicates(): void {
    const section = document.getElementById('ot-sdk-cookie-policy');
    const tally: { [key: string]: number } = {};

    if (section) {
      const children = Array.from(section.children);
      for (let i = children.length - 1; i >= 0; i--) {
        const childText = children[i].firstChild?.textContent;

        if (childText) {
          if (tally[childText] === undefined) {
            tally[childText] = 1;
          } else {
            section.removeChild(children[i]);
          }
        }
      }
    }
  }

  useEffect(() => {
    // Function to add onclick event to the cookie settings button.
    if (window.OneTrust != null && !isMobileApp) {
      clearDuplicates();
      setTimeout(function () {
        const cookieSettingButtons = Array.from(
          document.getElementsByClassName('ot-sdk-show-settings')
        ) as HTMLElement[];

        for (const btn of cookieSettingButtons) {
          if (
            typeof window.OneTrust !== 'undefined' &&
            typeof window.OneTrust.ToggleInfoDisplay === 'function'
          ) {
            btn.onclick = () => window.OneTrust?.ToggleInfoDisplay();
          }
        }
        // Wait 3 seconds so no mismatch between the initial, non-hydrated UI and the intended UI.
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    if (locale === countryCodes.ptBr) {
      return;
    }
    const hideSignInAlert = () => {
      sessionStorage.setItem('signInAlertShown', 'true');
      return null;
    };

    window.addEventListener('pagehide', hideSignInAlert);

    return () => {
      window.removeEventListener('pagehide', hideSignInAlert);
    };
  }, [locale]);

  useEffect(() => {
    if (locale === countryCodes.ptBr) {
      return;
    }
    const shouldDisplayLoginWelcome =
      localStorage.getItem('shouldDisplayLoginMsgOnReload') === 'true' &&
      localStorage.getItem('signInModalSelectedTab') === 'signIn' &&
      sessionStorage.getItem('isNewAccount') !== 'true' &&
      flowType !== 'manualCreateAccount';

    //Controls the sign in snack bar when site is reloaded
    if (
      shouldDisplayLoginWelcome &&
      appData.userAuthenticated &&
      !isMobileApp &&
      router.pathname !== routePaths.signIn
    ) {
      openSnackBar(lblYouAreNowSignedIn);
      sessionStorage.setItem('signInAlertShown', 'true');
      sessionStorage.setItem('signInAlertDisabled', 'true');
    }
    /* If it is cart page, we don't want to remove the variables yet.
      We want to remove them only after the automatic refresh that occurs after login
    */
    if (router.pathname !== routePaths.cart && router.pathname !== routePaths.signIn) {
      localStorage.removeItem('shouldDisplayLoginMsgOnReload');
    }
  }, [
    flowType,
    lblYouAreNowSignedIn,
    appData.userAuthenticated,
    router.pathname,
    isMobileApp,
    locale,
  ]);

  useEffect(() => {
    if (typeof root !== 'undefined') {
      root.onerror = (err) => {
        if (root?.utag_data) {
          root.utag_data.jsErrorMsg = err;
        }

        return true;
      };
    }
    /**Analytics End*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (locale === countryCodes.ptBr) {
      return;
    }
    const cookies = getDecodedCookies(['loginInteractionMethod', analyticsCookie.cookieisSignedIn]);

    const userName = localStorage.getItem('username') ?? '';
    const isResetPwFlow = ['resetPassword'].includes(
      localStorage.getItem('loggedInFrom') as string
    );
    const isManualCreateAccountFlow =
      flowType === 'manualCreateAccount' || sessionStorage.getItem('isNewAccount') === 'true';
    const isSelectedTabSignIn = localStorage.getItem('signInModalSelectedTab') === 'signIn';
    const delaySignInSnackBar = localStorage.getItem('delaySignInSnackBar');

    const handleSnackBar = () => {
      if (localStorage.getItem('delaySignInSnackBar') === 'abort') {
        return;
      }
      openSnackBar(lblYouAreNowSignedIn);
      sessionStorage.setItem('signInAlertShown', 'true');
      localStorage.removeItem('signInModalSelectedTab');
    };

    const shouldShowSnackBar = () => {
      const isSignInAlertDisabled = sessionStorage.getItem('signInAlertDisabled') === 'true';
      switch (true) {
        case isMobileApp:
          return false;
        case isManualCreateAccountFlow:
          return false;
        case isResetPwFlow:
          return false;
        case isSelectedTabSignIn:
          return true;
        case isSignInAlertDisabled:
          return false;
        default:
          return true;
      }
    };

    if (
      appData.userAuthenticated &&
      router.asPath !== routePaths.signIn &&
      router.pathname !== routePaths.signIn &&
      cookies.get(analyticsCookie.cookieisSignedIn) !== 'true'
    ) {
      //Controls the sign in snack bar when the site is not reloaded
      if (localStorage.getItem('shouldDisplayLoginMsgOnReload') !== 'true') {
        if (shouldShowSnackBar() && delaySignInSnackBar === null) {
          handleSnackBar();
        }
      }

      if (shouldShowSnackBar() && delaySignInSnackBar === 'true') {
        root.document.addEventListener('delayedSignInSnackBar', handleSnackBar);
      }

      const { loginFailureFlag } = root.utag_data || {};
      if (userName) {
        hashUserName(userName);
      }

      setCookie(analyticsCookie.cookieisSignedIn, true);
      localStorage.removeItem('loggedInFromMerge');
      localStorage.removeItem('loggedInFromEmptyCart');
      localStorage.removeItem('customLinkNameLoginMethod');

      if (loginFailureFlag) {
        delete root.utag_data?.loginFailureFlag;
      }
    }

    return () => {
      if (localStorage.getItem('delaySignInSnackBar') === 'true') {
        // Continue listening for delayedSignInSnackBar
        return;
      }
      document.removeEventListener('delayedSignInSnackBar', handleSnackBar);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.userAuthenticated, router.asPath]);

  const userName = headerData?.myAccountMap?.mf_homepage_header_username || '';

  useEffect(() => {
    if (
      headerData?.myAccountMap?.anonymous !== 'false' &&
      (!headerData?.storeNumber || headerData.storeNumber === defaultStoreUS) &&
      !isMobileApp &&
      !router.asPath.includes('spps.s') &&
      !router.asPath.includes('/checkout')
    ) {
      if (!isABot && coordinates !== null) {
        dispatch(setFetchingLocationStatus(true));
        const { latitude, longitude } = coordinates;

        const setStoreForApp = async () => {
          let storeNumber = locale !== countryCodes.ptBr ? defaultStoreUS : '';
          let storeCountryCode = '';

          try {
            const stores = await searchStore({
              location: [`${latitude}`, `${longitude}`],
              country: null,
              countryCode: null,
              includeFilters: false,
            });
            const firstOpenStore = stores.response.locations.find(
              (store) => !store?.closed?.isClosed
            );

            if (firstOpenStore) {
              storeNumber = firstOpenStore.id;
              storeCountryCode = firstOpenStore.countryCode;
            }

            const isOutOfBrazilStore = locale === countryCodes.ptBr && storeCountryCode !== 'BR';
            if (isOutOfBrazilStore) {
              storeNumber = '';
            }
          } catch (err: any) {
            logger.error({
              message: err.message,
            });
          } finally {
            if (storeNumber) {
              await setStore({ storeNumber });
              refetchHeaderData();
            }

            dispatch(setFetchingLocationStatus(false));
          }
        };

        setStoreForApp();
      }
    }
  }, [
    locale,
    headerData?.myAccountMap?.anonymous,
    isMobileApp,
    dispatch,
    headerData?.storeNumber,
    isABot,
    router.asPath,
    refetchHeaderData,
    coordinates,
    setStore,
    searchStore,
  ]);

  useEffect(() => {
    if (locale === countryCodes.ptBr) {
      return;
    }

    async function setStoreFromURL() {
      try {
        await setStore({ storeNumber: preferredStoreFromURL.current });
      } catch (err: any) {}
      refetchHeaderData();
    }
    if (storeID === preferredStoreFromURL.current) {
      setPageSpecificData({
        ignoreNextPageViewCall: false,
      });
      preferredStoreFromURL.current = '';
    } else if (preferredStoreFromURL.current) {
      setStoreFromURL();
      const [baseUrl, queryParams] = router?.asPath?.split('?');
      const paramsList = queryParams ? queryParams.split('&') : []; //Remove the store param from the list after setting the store.

      const taggedParamList = paramsList.concat('new_store_set=true');
      setPageSpecificData({
        ignoreNextPageViewCall: true,
      });

      const urlParams = new URLSearchParams(router.asPath.split('?')[1]);
      taggedParamList.forEach((param) => {
        const [key, value] = param.split('=');
        urlParams.set(key, value);
      });

      urlParams.set('new_store_set', 'true');

      router.push(`${baseUrl}?${urlParams.toString()}`, undefined, {
        shallow: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredStoreFromURL.current, storeID, refetchHeaderData]);

  useEffect(() => {
    setCookie(analyticsCookie.cookieuserVehicleCount, headerData?.vehicleMap.vehicleCount);
  }, [headerData, locale]);

  useEffect(() => {
    const isAddByURL = router.query.vehicleId === vehicleId && vehicleId !== prevSessionVehicleId;

    if (
      vehicleCount &&
      isUtagReady &&
      !isSignInModalOpened &&
      router.asPath !== routePaths.checkout &&
      router.asPath !== routePaths.signIn &&
      router.asPath !== routePaths.checkRewardAccount &&
      ((!isAddByURL && vehicleCount && vehicleId !== prevSessionVehicleId && !prevVehicleCount) ||
        (prevVehicleCount && (prevVehicleCount < vehicleCount || shouldRerenderToTrackAddVehicle)))
    ) {
      if (isUtagReady) {
        trackAddVehicle(isAddByURL, headerData);
        setShouldRerenderToTrackAddVehicle(false);
      } else {
        setShouldRerenderToTrackAddVehicle(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUtagReady, shouldRerenderToTrackAddVehicle, vehicleCount, prevVehicleCount]);

  useEffect(() => {
    const isAddByURL = router.query.vehicleId === vehicleId && vehicleId !== prevVehicleId;
    if (
      !!vehicleId &&
      !!prevVehicleId &&
      prevVehicleCount === preferredVehicleData?.vehicleCount &&
      vehicleId !== prevVehicleId &&
      isUtagReady
    ) {
      trackChangeVehicleSuccess(getVehicleAnalyticsData(isAddByURL));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUtagReady, vehicleId]);

  useEffect(() => {
    if (locale === countryCodes.ptBr) {
      return;
    }
    if (cartData) {
      setCookie('cartProductPartIds', String(cartData.cartProductPartIds));
      setCookie('cartProductSkus', String(cartData.cartProductSkus));
      setCookie('cartProductTitles', String(cartData.cartProductTitles));
      setCookie('cartProductVendors', String(cartData.cartProductVendors));
      setCookie('cartUnitPrice', String(cartData.cartUnitPrice));
      setCookie('cartCorePrice', String(cartData.cartCorePrice));
      setCookie('cartDiscountPriceList', String(cartData.cartDiscountPriceList));
    }
    analyticsGlobalVariables();
  }, [cartData, locale]);

  /*  This effect controls the snack bar that notifies of changes in the selected vehicle
    and redirects to the selected vehicle when the user is in a vehicle detail page
  */
  useEffect(() => {
    if (justMounted.current) {
      justMounted.current = false;
      return;
    }
    const userWasLoggedIn = !isAnonymousUser && prevAnonymousStatus === 'true';

    const routesToIgnore =
      [routePaths.customerInfo, routePaths.checkout].includes(
        getPathnameFromAsPath(router.asPath)
      ) || routePaths.resetPasswordPathName === router.pathname;

    if (
      (vehicleId || vehicleName) &&
      !routesToIgnore &&
      (vehicleId !== prevVehicleId || acesEnabled) &&
      prevHeaderDataUpdateAt &&
      localStorage.getItem('shouldDisplayLoginMsgOnReload') !== 'true' &&
      !userWasLoggedIn
    ) {
      if (
        router.route === `${routePaths.vehicleDetails}[vehicleId]` &&
        parseInt(vehicleCount ?? '0') > parseInt(prevVehicleCount ?? '0')
      ) {
        router.push(`${routePaths.vehicleDetails}${preferredVehicleData?.vehicleId}`);
      }

      openSnackBar(
        isMobileQRExperience
          ? `${lblScrollToSeeItemsThatFit} ${vehicleName}.`
          : `${lblShoppingFor} ${vehicleName}.`
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId, vehicleName]);

  useEffect(() => {
    if (router.asPath.includes('cmpid') && !isMobileQRExperience && vehicleId) {
      openSnackBar(`${lblShoppingFor} ${vehicleName}.`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
    FIXME: This is a very hacky solution, but scrolling to the top immediately scrolls to the location of the sticky
    header, hence the timeout. This can be removed once the review writing UI is controlled by the FE.
  */
  useEffect(() => {
    const modal = document.querySelector('.bv-mbox');
    if (modal) {
      const closeButton = modal.querySelector<HTMLButtonElement>('.bv-mbox-close');
      if (closeButton) {
        closeButton.click();
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 500);
      }
    }
  }, [router.asPath]);

  useEffect(() => {
    if (locale === countryCodes.ptBr) {
      return;
    }
    const isProtectedRoute = isRouteProtected(router.asPath);

    if (
      !router.asPath.includes('create') &&
      !router.asPath.startsWith(routePaths.completeAccount) &&
      router.asPath !== '/signin' &&
      router.pathname !== '/signin' &&
      router.asPath !== routePaths.createRewardsAccount &&
      router.asPath !== routePaths.checkRewardAccount
    ) {
      setCookie('prevUrlRouteValue', router.asPath);
      setCookie('redirect_url', router.asPath);
    }

    if (!appData.userAuthenticated) {
      if (isProtectedRoute) {
        router.replace(routePaths.signIn);
      }
    }

    if (!appData.userAuthenticated && isProtectedRoute && canShowToastNotification && !rememberMe) {
      router.replace(routePaths.signIn);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  const resetSnackBar = () => {
    setShowSnackBar(false);
  };

  const signedUserName = appData.userAuthenticated
    ? {
        userName,
      }
    : {};

  const currentPathIsCheckout = router.asPath.includes(routePaths.checkout);
  const currentPathIsHome = router.asPath === routePaths.blankURL;

  const headerPresent = appData.isHeaderPresent || appData.isSecondaryHeaderFooterPresent;
  const { url: currentUrl } = parseUrl(router.asPath);
  const routerActualPath = router.asPath || '';
  const splitRoute = routerActualPath ? routerActualPath?.split('/') : [];
  const isLandingPage = splitRoute[1] === 'lp';
  const isHomePage = currentUrl === routePaths.blankURL;
  const isChatPage = router.asPath === routePaths.chat;
  const isCustomerInfoPage = router.asPath === routePaths.customerInfo;
  let scripts;

  if (currentPathIsHome) {
    scripts = locale === countryCodes.us ? homePageMeta.app.scriptUS : homePageMeta.app.scriptMX;
  } else {
    scripts =
      locale === countryCodes.us ? config.app.mobileMeta.scriptUS : config.app.mobileMeta.scriptMX;
  }

  // Reset Password Toast Notification
  const canShowResetPasswordToastNotification =
    ![routePaths.signIn, routePaths.checkRewardAccount, routePaths.checkout].includes(
      router.asPath.split('?')[0]
    ) && !isAnonymousUser;
  const handleCloseResetPasswordToastNotification = () => {
    sessionStorage.removeItem('resetPasswordFrom');
    setIsResetPasswordToastNotificationOpen(false);
  };
  useEffect(() => {
    const onResetPasswordAndSignIn = () => {
      if (sessionStorage.getItem('resetPasswordFrom') === 'forgotPassword') {
        setIsResetPasswordToastNotificationOpen(true);
      }
    };
    document.addEventListener('resetPasswordAndSignIn', onResetPasswordAndSignIn);
    return () => {
      document.removeEventListener('resetPasswordAndSignIn', onResetPasswordAndSignIn);
    };
  }, []);
  useEffect(() => {
    if (sessionStorage.getItem('resetPasswordFrom') === 'forgotPassword') {
      setIsResetPasswordToastNotificationOpen(true);
    }
  }, []);

  useResetSignInNotifications(setIsResetPasswordToastNotificationOpen);

  const displayOneTrust =
    oneTrustEnabled && !isABot && !isMobileApp
      ? oneTrustDomainScriptUrl && oneTrustScriptUrl
        ? true
        : false
      : false;

  return (
    <div
      className={
        !currentPathIsCheckout
          ? styles.App
          : checkoutVersionEnableV3_us || checkoutVersionEnableV3_mx
          ? styles.checkoutAppV3
          : styles.checkoutApp
      }
    >
      <Head>
        <meta name="google-site-verification" content={googleSiteVerificationKey} />
        {customScripts?.map(
          ({ c, async, ...script }: { c?: string; async?: boolean }, i: number) => {
            return (
              <script
                data-injected
                key={`custom-script-${i}`}
                async={async ?? true}
                dangerouslySetInnerHTML={c ? { __html: c } : undefined}
                {...script}
              />
            );
          }
        )}
        {displayOneTrust && (
          <>
            <link rel="preconnect" href={oneTrustOrigin} />
            <script
              src={oneTrustScriptUrl}
              type="text/javascript"
              data-domain-script={oneTrustDomainScriptUrl}
              defer
            />
            <script
              defer
              type="text/javascript"
              dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
            />
            <script
              dangerouslySetInnerHTML={{
                __html:
                  'window.addEventListener("OTConsentApplied",function(){location.reload()});',
              }}
            />
          </>
        )}
        {kiboEnabled && kiboScriptUrl && (
          <>
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{ __html: 'var monetateT = new Date().getTime();' }}
            />
            {/* eslint-disable-next-line */}
            <script type="text/javascript" src={kiboScriptUrl} />
          </>
        )}
        {scripts.map((script, ind) => (
          <script
            type={script.type}
            key={`script-${ind}`}
            dangerouslySetInnerHTML={{
              __html: script.innerHTML,
            }}
          />
        ))}
      </Head>
      {isSmartAppBannerEnabled && !isAppFlyerEnabled && !isMobileQRExperience ? (
        <SmartAppBanner />
      ) : null}

      {appData.isHeaderPresent && (!isBrazil || (isBrazil && isBrazilPhaseTwo)) && <Header />}
      {isBrazil && !isBrazilPhaseTwo && <BrazilHeader />}

      {appData.isSecondaryHeaderFooterPresent && <HeaderSecondary />}
      {contactUsModalEnabled &&
      !currentPathIsCheckout &&
      !isChatPage &&
      !isCustomerInfoPage &&
      !isMobileQRExperience &&
      locale !== countryCodes.ptBr ? (
        <>
          <NeedHelpTab onClick={openContactUsModal} />
          <ContactUsModal
            isContactUsModalOpen={isContactUsModalOpen}
            setIsContactUsModalOpen={setIsContactUsModalOpen}
          />
        </>
      ) : null}
      {!contactUsModalEnabled && appData.isChatAgentAvailable ? <ChatSlider /> : null}
      {/* {showResetPasswordMessage && canResetPasswordNotificationOpen && ( */}
      {isResetPasswordToastNotificationOpen && canShowResetPasswordToastNotification && (
        <ContentContainer type="simple" withPadding>
          <Grid container justify="space-around">
            <Grid item lg={8} md={12} xs={12}>
              <ResetPasswordMessageV2
                successData={{ userName: signedUserName.userName ?? '' }}
                onClose={handleCloseResetPasswordToastNotification}
                canOpen={isResetPasswordToastNotificationOpen}
              />
            </Grid>
          </Grid>
        </ContentContainer>
      )}

      <AZSnackBar
        open={showSnackBar}
        message={snackBarMessage}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={resetSnackBar}
      />
      {isBanner90Open && <LazyBanner90Day />}
      {isRewardsPilotProgramBannerOpen && <LazyRewardsPilotProgramBanner />}
      {isHomePage ||
      router.asPath === routePaths.userMyAccount ||
      router.asPath === routePaths.sms ? (
        <main
          className={cx({
            [styles.fullWidth]: headerPresent,
          })}
          role="main"
          data-testid={headerPresent ? 'webel-home-page-display' : ''}
          id={headerPresent ? 'webel-home-page-display' : ''}
          tabIndex={0}
        >
          {children}
        </main>
      ) : (
        <main
          className={
            headerPresent && !isLandingPage && !currentPathIsCheckout
              ? azCommonStyles.mainContent
              : ''
          }
          role="main"
          tabIndex={0}
        >
          {children}
        </main>
      )}

      {appData.isFooterPresent && (!isBrazil || (isBrazil && isBrazilPhaseTwo)) && <Footer />}
      {isBrazil && !isBrazilPhaseTwo && <BrazilFooter />}

      {appData.isSecondaryHeaderFooterPresent && <FooterSecondary />}
      {appData.isChatAgentAvailable ? <ProactiveChat /> : null}

      {!isABot && (
        <>
          <FeatureFlag flag="TEALEAF_ENABLED">
            <Script type="text/javascript" src="/js/tealeaf_6_1.min.js" strategy="lazyOnload" />
          </FeatureFlag>
          <Utag />
          <UtagSync />
        </>
      )}
      {locale === countryCodes.enUS && (
        <Script
          type="text/javascript"
          src="//edge1.certona.net/cd/87681bc5/autozone.com/scripts/resonance.js"
          strategy="beforeInteractive"
        />
      )}
      {performanceLCPMeasurementsEnabled && <LazyLCPMeasurements />}
    </div>
  );
};

export default App;
