/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { FooterData, FooterMiddle, FooterTopOrBottom } from '../interface';
import { getAxios } from '@/lib/axios';
import { type AxiosInstance } from 'axios';
import { createQuery } from '@/utils/createReactQuery';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import type { FormattedContentStackFooter } from '@/features/contentstack/interface';
import { getFooterContent } from '@/features/contentstack/lib/footer/getFooterContent'; // eslint-disable-line no-restricted-imports
import { type QueryClient } from '@tanstack/react-query';
import { type PageContextType } from '@/next-types';

const CMS_URL = '/api/contentstack/footer';

type Options = {
  // we're using locale only to have separate cache for US and MX pages
  locale: Locale;
};

const getCMSFooterContent = async (axiosInstance?: AxiosInstance) => {
  const { data } = await getAxios(axiosInstance).get<FormattedContentStackFooter>(CMS_URL);

  return selector(data);
};

const { useData: useFooter, query: footerQuery } = createQuery<FooterData | null, Options>(
  'footerData',
  async (options, axiosInstance) => getCMSFooterContent(axiosInstance)
);

const middleSctionMapper = (
  middleFooter: FormattedContentStackFooter['footerMiddle']
): FooterMiddle[] | undefined => {
  if (!middleFooter) {
    return;
  }

  return middleFooter.footer_middle_group?.map((footerContentGroup) => ({
    title: footerContentGroup.title ?? '',
    displayPosition: footerContentGroup.display_position ?? 'Horizontal',
    middleLabelContent: (footerContentGroup.menu_link ?? []).map((link) => ({
      name: link.link_name ?? '',
      CTALink: link.link_cta ?? '',
    })),
    name: footerContentGroup.title ?? '',
  }));
};

const topSctionMapper = (
  topFooter: FormattedContentStackFooter['footerTop']
): FooterTopOrBottom[] | undefined => {
  if (!topFooter) {
    return;
  }

  const getCtaLink = (ctaLink: string | undefined) => (ctaLink?.startsWith('##') ? '' : ctaLink);

  return topFooter.menu_link?.map((footerContentGroup) => ({
    CTALink: getCtaLink(footerContentGroup.link_cta) ?? '',
    imageUrl: footerContentGroup.image ?? '',
    name: (footerContentGroup.link_name || footerContentGroup.alt_text) ?? '',
    altText: footerContentGroup.alt_text ?? '',
    label: footerContentGroup.link_name ?? '',
    Icon: !footerContentGroup.link_name,
    displayPosition: footerContentGroup.display_position,
  }));
};

const bottomSectionMapper = (
  bottomFooter: FormattedContentStackFooter['footerBottom']
): FooterTopOrBottom[] | undefined => {
  if (!bottomFooter) {
    return;
  }

  return bottomFooter.menu_link?.map((bottomMenuLink) => ({
    altText: bottomMenuLink.alt_text ?? '',
    label: bottomMenuLink.link_name ?? '',
    imageUrl: bottomMenuLink.image ?? '',
    name: bottomMenuLink.alt_text ?? '',
    CTALink: bottomMenuLink.link_cta ?? '',
    Icon: !!bottomMenuLink.image,
    displayPosition: bottomMenuLink.display_position,
  }));
};

const selector = (data: FormattedContentStackFooter): FooterData | null => {
  if ('footerTop' in data) {
    return {
      footerMiddle: middleSctionMapper(data.footerMiddle) ?? [],
      footerTop: topSctionMapper(data.footerTop) ?? [],
      footerBottom: bottomSectionMapper(data.footerBottom) ?? [],
    };
  }

  return null;
};

const useFooterData = () => {
  const locale = useLocale();

  return useFooter({
    locale,
    staleTime: Infinity,
  });
};

const prefetchCMSFooterContent = (
  queryClient: QueryClient,
  context: PageContextType,
  options: Options
) => {
  return queryClient.prefetchQuery({
    queryKey: footerQuery.getFullKey(options),
    queryFn: async () => {
      const footerData = await getFooterContent(context.req, context.query);
      return selector(footerData);
    },
  });
};

export { prefetchCMSFooterContent, useFooterData };
