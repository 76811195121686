/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import CookieButtonStyles from './CookieButton.module.scss';
import React, { useEffect, useState } from 'react';
import { useFeatureFlag } from '@/features/globalConfig';
import { useDeviceType } from '@/utils/useDeviceType';
import { useLabels } from '@/hooks/useLabels';
import cx from 'classnames';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type CookieButtonProps = {
  isLinkColumn?: boolean;
};

const labelMap = {
  cookieSettings: 'labels_Cookie_Settings',
};

export function CookieButton({ isLinkColumn = false }: CookieButtonProps) {
  const deviceType = useDeviceType();
  const isABot = deviceType === 'bot';
  const oneTrustLinkEnabled = useFeatureFlag('ONE_TRUST_LINK_ENABLED') === 'true' && !isABot;

  const [showAlertNotice, setShowAlertNotice] = useState(false);

  useEffect(() => {
    let checkForOneTrust: NodeJS.Timeout;
    if (oneTrustLinkEnabled) {
      checkForOneTrust = setInterval(() => {
        if (
          /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call */
          typeof window.OneTrust !== 'undefined' &&
          typeof window.OneTrust.GetDomainData === 'function'
        ) {
          setShowAlertNotice(window.OneTrust.GetDomainData().ShowAlertNotice);
          clearInterval(checkForOneTrust);
        }
      }, 1000);
    }

    return () => {
      clearInterval(checkForOneTrust);
    };
  }, [oneTrustLinkEnabled]);

  const displayCookieBtn = oneTrustLinkEnabled && showAlertNotice;

  return displayCookieBtn ? (
    isLinkColumn ? (
      <li className={CookieButtonStyles.cookieButtonElement}>
        <span className={CookieButtonStyles.cookieStick} />
        <Button />
      </li>
    ) : (
      <Button />
    )
  ) : null;
}

const Button = () => {
  const labels = useLabels(labelMap);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <button
      id="ot-sdk-btn"
      className={cx(
        CookieButtonStyles.commonCookieBtnStyles,
        {
          [CookieButtonStyles.linkItem]: !isMobile,
          [CookieButtonStyles.labelLinkItem]: isMobile,
        },
        'ot-sdk-show-settings'
      )}
    >
      {labels.cookieSettings}
    </button>
  );
};
