/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import type { FooterMiddle } from '../interface';
import styles from './FooterMiddle.module.scss';
import { LinkColumnContainer, OtherAutoZoneSitesContainer } from './LinkColumn';
import { MobileLinkColumn } from './MobileLinkColumn';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Grid } from '@/components/Grid';

type Props = {
  content: FooterMiddle[] | undefined;
};

export function FooterMiddle({ content }: Props) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (!content || content.length === 0) {
    return null;
  }

  const linksColumns = content.filter(
    (contentBlock) => contentBlock.displayPosition === 'Vertical'
  );

  const otherAutozoneSites = content.filter(
    (contentBlock) => contentBlock.displayPosition === 'Horizontal'
  )[0];

  return (
    <div className={styles.footerMiddleContainer} data-testid="footer_middle_container">
      <ul className={styles.footerMiddleLinksColumnContainer}>
        {linksColumns.map((linksColumn, index) => {
          return (
            <Grid
              component={'li'}
              item
              container
              lg={3}
              md={4}
              key={`linkColumnContainer_${index}`}
            >
              <div className={styles.columnsContainer}>
                <LinkColumnContainer
                  title={linksColumn.title}
                  middleLabelContent={linksColumn.middleLabelContent}
                />
              </div>
            </Grid>
          );
        })}
      </ul>
      {isMobile ? (
        <ul className={styles.footerMiddleMobileLinksColumnContainer}>
          {content.map((linksColumn, index) => (
            <MobileLinkColumn
              key={`mobile_linkx_${index}`}
              title={linksColumn.title}
              middleLabelContent={linksColumn.middleLabelContent}
            />
          ))}
        </ul>
      ) : otherAutozoneSites ? (
        <div className={styles.otherSites}>
          <OtherAutoZoneSitesContainer
            title={otherAutozoneSites.title}
            middleLabelContent={otherAutozoneSites.middleLabelContent}
          />
        </div>
      ) : null}
    </div>
  );
}
